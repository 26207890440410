import React, { useState } from 'react';

import { Alert, Button } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { EMPTY_ARRAY } from 'constants/atoms';

import dictionaryStore from 'store/models/DictionaryStore';

import {
    DICTIONARY_LABELS_VOTING,
    DICTIONARY_STATUSES,
    DICTIONARY_STATUSES_VOTING,
    DICTIONARY_TYPES,
} from 'utils/consts';
import queryInText from 'utils/queryInText';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DictionaryValueItem from 'components/DictionaryValueItem';
import Flex from 'components/Flex';
import Space from 'components/Space';

import { itemRenderer } from './itemRenderer';

function DictionaryRemoveAlert({ board }) {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(null);
    const [dictionary, setDictionary] = useState(null);
    const [active, setActive] = useState(null);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState('');

    function clearAll() {
        setOpen(false);
        setId(null);
        setDictionary(null);
        setList([]);
        setActive(null);
        setQuery('');
    }

    reaction(
        () => board?.alertBox,
        (alertBox) => {
            if (alertBox) {
                setOpen(true);
                setId(alertBox.id);
                setDictionary(alertBox.dictionary);
                const list = board.getDictByName(alertBox.dictionary) || EMPTY_ARRAY;
                const selectItems = list.filter((el) => el.id !== alertBox.id);
                setList(selectItems);
                setActive(selectItems[0]);
            } else if (open) {
                clearAll();
            }
        },
    );

    let placeholder;
    if (DICTIONARY_STATUSES === dictionary) {
        placeholder = 'status';
    } else if (DICTIONARY_TYPES === dictionary) {
        placeholder = 'type';
    }

    const hideColor = dictionary === DICTIONARY_STATUSES_VOTING;

    function removeItem() {
        if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
            board?.removeDictItem({ dictionary, id, new_id: active.id });
        } else {
            dictionaryStore.removeDictItem({ dictionary, id, new_id: active.id });
        }
    }

    return (
        <>
            <Alert
                cancelButtonText="Cancel"
                confirmButtonText="Remove"
                onCancel={clearAll}
                onConfirm={removeItem}
                isOpen={open}
                intent="danger"
                canEscapeKeyCancel
                canOutsideClickCancel
                portalClassName="bp5-portal-alert"
            >
                <p className="bp5-text-large">
                    Are you sure you want to remove the {placeholder}? All issues with this {placeholder} will be
                    changed.
                </p>
                <Space height={10} />
                <Flex gap={6} wrap>
                    <span>Choose another {placeholder} to replace</span>
                    <Select
                        query={query}
                        popoverProps={{ minimal: true, className: 'bp5-select-popover', usePortal: false }}
                        activeItem={active}
                        onItemSelect={setActive}
                        items={list}
                        itemRenderer={(item, props) => itemRenderer(item, props, hideColor)}
                        itemPredicate={(query, item) => queryInText(item.name, query)}
                        onQueryChange={setQuery}
                        inputProps={{
                            className: 'custom-select-clear',
                            leftIcon: <CustomIcon icon={CustomIconName.SEARCH} />,
                        }}
                    >
                        <Button
                            small
                            text={
                                (active && (
                                    <DictionaryValueItem
                                        color={hideColor ? undefined : active.color}
                                        name={active.name}
                                    />
                                )) ||
                                'Unknown'
                            }
                            rightIcon="chevron-down"
                        />
                    </Select>
                </Flex>
                <Space height={20} />
            </Alert>
        </>
    );
}

export default observer(DictionaryRemoveAlert);

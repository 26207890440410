import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StAlignment } from 'atoms/StAlignment';

import isEmptyValue from 'utils/isEmptyValue';

function CellAlignmentContent({ value = null, fieldRed, fieldYellow, hideStatus = false, ...props }, forwardedRef) {
    if (hideStatus || isEmptyValue(value)) {
        return (
            <StAlignment ref={forwardedRef} {...props}>
                {'-'}
            </StAlignment>
        );
    }

    const board = mainStore.activeBoard || issuesList.activeIssue?.board;
    if (!board) return null;

    const alignmentRed = board.settings[fieldRed];
    const alignmentYellow = board.settings[fieldYellow];

    const isDanger = value >= 0 && value <= alignmentRed;
    const isWarning = value && value > alignmentRed && value <= alignmentYellow;

    return (
        <StAlignment cell danger={isDanger} warning={isWarning} ref={forwardedRef} {...props}>
            {value}
        </StAlignment>
    );
}

export default observer(React.forwardRef(CellAlignmentContent));

import React, { useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';

import { groupBy } from 'utils/groupBy';

export default function VirtuosoList({
    renderItem,
    activeItem,
    activeItemIndex,
    sizeItem = 50,
    maxCount = 10,
    className,
    scrollerRef,
    width = 280,
    filteredItems = [],
    noResults,
    renderCreateItem,
    groups,
    baseGroups,
    createItemAllTime,
}) {
    const ref = useRef(null);

    const itemIndex = activeItemIndex(filteredItems, activeItem);

    useEffect(() => {
        ref?.current?.scrollIntoView({
            index: itemIndex,
            behavior: 'auto',
        });
    }, [itemIndex]);

    const getGroups = useCallback(() => {
        if (!groups) return {};

        const a = groupBy(filteredItems, (item) => {
            return baseGroups.findIndex((items) => items.includes(item));
        });

        return Object.entries(a).reduce(
            (res, [index, items]) => {
                res.groupsNames.push(groups[+index]);
                res.groupCounts[+index] = items.length;

                return res;
            },
            { groupsNames: [], groupCounts: [] },
        );
    }, [groups, filteredItems, baseGroups]);

    const maybeNoResults = (typeof renderCreateItem === 'function' && renderCreateItem()) || noResults;

    const classes = classNames('bp5-select-virtuoso', className);

    if (filteredItems.length === 0 && maybeNoResults)
        return (
            <div className={classes} style={{ width }}>
                {maybeNoResults}
            </div>
        );

    const showCreateItem = typeof renderCreateItem === 'function' && createItemAllTime;

    const height =
        filteredItems.length > maxCount ? sizeItem * maxCount + sizeItem / 2 : filteredItems.length * sizeItem;

    if (groups) {
        const { groupsNames, groupCounts } = getGroups();
        const headersHeight = groupsNames.length * 32;

        return (
            <GroupedVirtuoso
                groupCounts={groupCounts}
                className={classes}
                scrollerRef={scrollerRef}
                ref={ref}
                style={{ height: headersHeight + height, width }}
                groupContent={(index) => {
                    return (
                        <div
                            style={{
                                background: 'var(--colors-bg2)',
                                padding: '12px 2px 2px 2px',
                                color: 'var(--colors-tc2)',
                                height: 32,
                            }}
                        >
                            {groupsNames[index]}
                        </div>
                    );
                }}
                itemContent={(index) => renderItem(filteredItems[index], index)}
            />
        );
    }

    const getFooter = () => (typeof renderCreateItem === 'function' && createItemAllTime ? renderCreateItem() : null);

    return (
        <Virtuoso
            initialTopMostItemIndex={itemIndex > 0 ? itemIndex - 1 : 0}
            className={classes}
            scrollerRef={scrollerRef}
            ref={ref}
            style={{ height: showCreateItem ? height + 30 : height, width }}
            data={filteredItems}
            itemContent={(index, item) => renderItem(item, index)}
            components={{
                Footer: getFooter,
            }}
        />
    );
}

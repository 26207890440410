import { runInAction } from 'mobx';

import api from 'api';
import { sendToSentry, toUrl } from 'utils';

import { Board } from 'store/models/Board';
import { mainStore } from 'store/models/MainStore';

import { DISABLE_SURVEY } from 'utils/consts';

function getSurveyFormData({ framework = null, task_tracker = null }) {
    if (DISABLE_SURVEY) return false;
    if (framework || task_tracker) return { framework, task_tracker };
    return true;
}

export async function createDucalisBoard(framework = null, setName = null, task_tracker) {
    const platform = this.platformsList.ducalisPlatform;
    if (!platform) {
        sendToSentry('Fail create board - empty Service platform');
        return await Promise.reject();
    }
    try {
        const { data } = await api.post('/boards', toUrl({ no_issues: true }), {
            params: { platform_id: platform.id, framework: framework || 'impact-effort-matrix', setName },
        });
        const board = mainStore.boardsList.boards.find((board) => board.id === data.id);
        const showSurveyForm = getSurveyFormData({ framework, task_tracker });

        if (board) {
            runInAction(() => {
                board.showSurveyForm = showSurveyForm;
                board.fillModel(data);
            });
        } else {
            runInAction(() => {
                const newBoard = new Board(data);
                newBoard.showSurveyForm = showSurveyForm;
                mainStore.boardsList.boards.push(newBoard);
            });
        }
        return data.id;
    } catch (e) {
        return await Promise.reject(e);
    }
}

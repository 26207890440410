import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { issuesList } from 'store/models/IssuesList';

import { DICTIONARY_TYPES } from 'utils/consts';

import DictionaryValueItem from 'components/DictionaryValueItem';
import DictionaryValueSelect from 'components/DictionaryValueSelect';
import MetaItem from 'components/MetaItem';

const Type = () => {
    const issue = issuesList.issue;

    if (issue.isEditable) {
        return (
            <MetaItem title="Type">
                <DictionaryValueSelect dictionary={DICTIONARY_TYPES} issue={issue} />
            </MetaItem>
        );
    }

    let type = issue.typeObj;

    if (type) {
        if (issue.provider === PROVIDER_DUCALIS && typeof type !== 'string') {
            return (
                <MetaItem title="Type">
                    <DictionaryValueItem {...type} tag />
                </MetaItem>
            );
        }
        return (
            <MetaItem title="Type" fillFree={type?.length < 13}>
                <DictionaryValueItem name={type} tag />
            </MetaItem>
        );
    }
    return null;
};

export default observer(Type);

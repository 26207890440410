import React from 'react';

import { observer } from 'mobx-react-lite';

import { RewriteValue } from './RewriteValue';

const AverageScoreItem = observer(({ issue, criterion }) => {
    const rewritten = issue.externalData && issue.externalData.custom_votes.has(criterion.id);
    const value = issue.externalData.cr_weightless.get(criterion.id);

    return (
        <td key={criterion.id}>
            <div className="t-r t-w-sb">
                {rewritten ? (
                    <RewriteValue
                        value={value}
                        criterion={criterion}
                        vote={issue.externalData.custom_votes.get(criterion.id)}
                    />
                ) : (
                    (value ?? '')
                )}
            </div>
        </td>
    );
});

function AverageScore({ issue, criteria }) {
    return criteria.map((criterion) => <AverageScoreItem key={criterion.id} criterion={criterion} issue={issue} />);
}

export default observer(AverageScore);

import React, { forwardRef, useEffect, useMemo, useState } from 'react';

import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import Youtube from '@tiptap/extension-youtube';
import { BubbleMenu, EditorContent, isTextSelection, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';

import screenStore from 'store/models/ScreenStore';

import { DOMAIN_NAME, REMOVE_NOFOLLOW_LINK } from 'utils/consts';

import { DEFAULT_TOOLBAR_STATE, TOOLBAR_ACTIONS } from 'components/TextEditor/ToolBar/constants';

import Audio from './Extensions/Audio';
import Image from './Extensions/Image';
import Link from './Extensions/Link';
import CustomMention, { suggestionSimple, suggestionAsync } from './Extensions/Mention/CustomMention';
import { SaveHotKeyExtension } from './Extensions/SaveHotKey';
import Video from './Extensions/Video';
import { ImageToolbar, ToolBar } from './ToolBar';
import FileUploaderBtn from './ToolBar/FileUploaderBtn';

import styles from './TextEditor.module.sass';

const BubbleMenuImageOptions = { offset: [-4, -28], placement: 'top-end', zIndex: 1 };
const BubbleMenuToolbarOptions = { duration: 100, maxWidth: 'none', placement: 'top' };

const TextEditor = forwardRef(
    (
        {
            description,
            onConfirm,
            onChange,
            placeholder,
            minimal,
            sendMethod,
            tabIndex = -1,
            className,
            disabled,
            defaultValue,
            autoFocus,
            hasMention,
            hasPublicMention,
            readOnly,
            onFocus,
            disableUploads,
            allowYoutube,
            children,
            parseOptions,
            isTypography = true,
            actions = DEFAULT_TOOLBAR_STATE,
        },
        forwardedRef,
    ) => {
        const [wrapperFocus, setWrapperFocus] = useState(false);
        const [isFocus, setIsFocus] = useState(false);
        const [content, setContent] = useState(description);
        const [send, setSend] = useState(false);

        useEffect(() => {
            onFocus?.(isFocus);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isFocus]);

        useEffect(() => {
            if (content !== description) {
                onConfirm?.(content);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [content]);

        useEffect(() => {
            if (send) {
                sendMethod?.();
                setSend(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [send]);

        const hasSendMethod = typeof sendMethod === 'function';

        const extensions = useMemo(() => {
            const extensions = [
                StarterKit,
                Typography,
                Placeholder.configure({
                    showOnlyWhenEditable: false,
                    showOnlyCurrent: false,
                    placeholder: placeholder || 'Edit description',
                }),
            ];

            if (actions.flat().includes(TOOLBAR_ACTIONS.link)) {
                extensions.push(
                    Link.configure({
                        clearDomainRel: REMOVE_NOFOLLOW_LINK,
                        mainDomain: DOMAIN_NAME,
                    }),
                );
            }

            if (!disableUploads) {
                extensions.push(Image);
                extensions.push(Video);
                extensions.push(Audio);
            }

            if (allowYoutube) {
                extensions.push(Youtube.configure({ allowFullscreen: true }));
            }

            if (hasSendMethod) {
                extensions.push(
                    SaveHotKeyExtension.configure({
                        action: () => setSend((state) => !state),
                    }),
                );
            }

            if (hasMention) {
                extensions.push(
                    CustomMention.configure({
                        suggestion: hasPublicMention ? suggestionAsync : suggestionSimple,
                    }),
                );
            }

            return extensions;
        }, [actions, allowYoutube, disableUploads, hasMention, hasPublicMention, placeholder, hasSendMethod]);

        const editor = useEditor({
            extensions,
            tabIndex,
            autofocus: autoFocus ? 'end' : false,
            content: description || defaultValue || '',
            onFocus() {
                !readOnly && setIsFocus(true);
            },
            onUpdate({ editor }) {
                !readOnly && onChange && onChange(editor.isEmpty ? '' : editor.getHTML());
            },
            onBlur({ editor, event }) {
                if (readOnly) {
                    return;
                }
                if (event?.relatedTarget?.className?.indexOf('bp5-menu') >= 0) {
                    editor.view.focus();
                    return false;
                }
                setIsFocus(false);
                onConfirm && setContent(editor.isEmpty ? '' : editor.getHTML());
            },
            editable: !readOnly,
            parseOptions,
        });

        useEffect(() => {
            if (!isFocus && editor && editor.getHTML() !== description) {
                editor.commands.setContent(description);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [description]);

        if (editor === null) return null;

        const classes = classNames(
            {
                [styles.editor]: !minimal,
                [styles.editorActive]: !readOnly && !minimal && isFocus,
            },
            className,
        );

        if (readOnly) {
            return (
                <div className={classes} aria-disabled={disabled} aria-readonly={readOnly}>
                    <EditorContent className="typography" editor={editor} />
                </div>
            );
        }

        const fileBtnClass = classNames(styles.fileBtn, {
            [styles.fileBtn__show]: isFocus || wrapperFocus,
            [styles.fileBtn__mobile]: screenStore.isMobile,
        });

        return (
            <div
                className={classes}
                aria-disabled={disabled}
                onFocus={() => setWrapperFocus(true)}
                onBlur={() => setWrapperFocus(false)}
                ref={forwardedRef}
            >
                <BubbleMenu
                    shouldShow={() => {
                        return editor.isActive('image') || editor.isActive('video') || editor.state.selection.empty
                            ? false
                            : isTextSelection(editor.state.selection);
                    }}
                    editor={editor}
                    pluginKey="bubbleMenuMain"
                    tippyOptions={BubbleMenuToolbarOptions}
                >
                    <ToolBar actions={actions} editor={editor} />
                </BubbleMenu>

                <BubbleMenu
                    editor={editor}
                    tippyOptions={BubbleMenuImageOptions}
                    pluginKey="bubbleMenuImage"
                    shouldShow={() => editor.isActive('image')}
                >
                    <ImageToolbar editor={editor} />
                </BubbleMenu>

                <EditorContent className={isTypography ? 'typography' : undefined} editor={editor} />

                {!disableUploads && (
                    <div className={fileBtnClass} data-place="upload-btn">
                        <FileUploaderBtn editor={editor} />
                    </div>
                )}

                {children}
            </div>
        );
    },
);

export default TextEditor;

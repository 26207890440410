import { makeAutoObservable } from 'mobx';

import { collectionUtils } from 'store/models/utils/collectionsUtils';

import { CURRENT_USER_ID, ROLES_GROUPS, USER_ROLE, USER_STATUS } from 'utils/consts';
import { groupBy } from 'utils/groupBy';
import { orderUsers } from 'utils/orderUsers';

import { mainStore } from './MainStore';
import { User } from './User';

/**
 * Filter unused fields from users
 *
 * @param data
 * @return {{}}
 */
function prepareUserObject(data) {
    const fields = [
        'avatar',
        'created',
        'email',
        'id',
        'invited',
        'is_owner',
        'last_activity',
        'last_login',
        'name',
        'platformIDs',
        'role',
        'status',
        'can_manage',
        'bot_users',
        'opt_in',
        'removed',
        'organizations',
        'release_note_subscribed_boards',
        'has_fake_email',
        'session_id',
        'voting_user_id',
    ];

    return Object.entries(data).reduce((acc, [key, value]) => {
        if (fields.includes(key) && typeof value !== 'undefined') {
            acc[key] = value;
        }
        return acc;
    }, {});
}

export default class Users {
    usersIds = new Map();
    currentUser = new User({ id: CURRENT_USER_ID });

    constructor() {
        makeAutoObservable(this);
    }

    set = (users) => {
        const clearUsers = users.map(prepareUserObject);
        collectionUtils.updateMap(this.usersIds, clearUsers);
    };

    setCurrentUser = (data) => {
        this.currentUser.fillModel(data);
    };

    get users() {
        return Array.from(this.usersIds.values());
    }

    get activeUsers() {
        return this.users.filter((user) => user.status === USER_STATUS.active);
    }

    get activeOrderedUsers() {
        return orderUsers(this.activeUsers);
    }

    get activeUsersWithoutViewers() {
        return this.activeOrderedUsers.filter((user) => user.role !== USER_ROLE.Viewer);
    }

    get activeUsersWithoutMe() {
        return this.activeUsers.filter((el) => el.id !== CURRENT_USER_ID);
    }

    getGroupsUsersByRole(list) {
        const groups = groupBy(list, (user) => ROLES_GROUPS[user.role]);

        if (groups.Admins === undefined) groups.Admins = [];
        if (groups.Members === undefined) groups.Members = [];
        if (groups.Viewers === undefined) groups.Viewers = [];

        groups.Pending = list.filter((user) => user.status === USER_STATUS.pending);

        return groups;
    }

    get orderedUsers() {
        const users = this.users.filter((user) => user.status !== USER_STATUS.removed);
        return orderUsers(users);
    }

    get removedUsers() {
        const users = this.users.filter((user) => ![USER_STATUS.active, USER_STATUS.pending].includes(user.status));
        return orderUsers(users);
    }

    get owner() {
        return this.users.find((el) => el.is_owner);
    }

    get adminsStringList() {
        return this.activeUsers
            .filter((user) => user.role === USER_ROLE.Admin)
            .map((user) => user.name)
            .join(', ');
    }

    get adminsEmailsList() {
        return this.activeUsers
            .filter((user) => user.role === USER_ROLE.Admin)
            .map((user) => user.email)
            .join(', ');
    }

    getActiveUsersWithoutIds(usersIds = [], ignoreViewer = false) {
        const usersList = ignoreViewer ? this.users.filter((user) => user.role !== USER_ROLE.Viewer) : this.users;
        const filteredList = usersList.filter(
            (user) => !usersIds.includes(user.id) && [USER_STATUS.active, USER_STATUS.pending].includes(user.status),
        );

        return orderUsers(filteredList);
    }

    getActiveUsersByIds(ids) {
        return ids.reduce((res, userId) => {
            const user = mainStore.users.usersIds.get(userId);
            if (user && user.status !== USER_STATUS.removed) {
                res.push(user);
            }
            return res;
        }, []);
    }

    update(data, isNew) {
        if (data.id === mainStore.currentUser.id && data.status === USER_STATUS.removed) {
            mainStore.db.dropDB();
            return (window.location.href = '/login/logout');
        }

        if (data.id === CURRENT_USER_ID) {
            this.currentUser.fillModel(data);
        }
        if (isNew) {
            this.usersIds.set(data.id, data);
        } else if (this.usersIds.has(data.id)) {
            Object.assign(this.usersIds.get(data.id), data);
        }
    }

    removeSingle(userId) {
        if (this.currentUser.id === userId) {
            mainStore.db.dropDB();
            return (window.location.href = '/login/logout');
        }
        this.usersIds.delete(userId);
    }
}

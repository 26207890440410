import React from 'react';

import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StCardStripe, StCardStripeHeader, StCardStripeHiddenActions } from 'atoms/StCardStripe';

import { IS_PUBLIC_BOARD } from 'utils/consts';
import delay from 'utils/delay';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';
import Tooltip from 'components/Tooltip';

import { DoneIssueName } from './DoneIssueName';
import { IssueTooltip } from './IssueTooltip';
import { StLink } from './StLink';

function BlocksIssueItem({ item, onRemove, title, removeTooltip }) {
    let navigate = useNavigate();
    const issue = issuesList.allIssues.find((el) => el.id === item.id);

    const name = issue?.fullName || item.name;
    const isDone = issue?.isDone;

    const button =
        !mainStore.currentUser?.isViewer && !IS_PUBLIC_BOARD ? (
            <Tooltip side="top" content={removeTooltip}>
                <StCardStripeHiddenActions>
                    <Button
                        block
                        color="red"
                        icon={CustomIconName.CROSS_M}
                        minimal
                        size={24}
                        border
                        onClick={() => onRemove(item.id)}
                    />
                </StCardStripeHiddenActions>
            </Tooltip>
        ) : (
            <Space width={8} />
        );

    if (!issue) {
        return (
            <StCardStripe as={Flex} gap={8}>
                <StCardStripeHeader as={Flex} style={{ cursor: 'default' }}>
                    <Tooltip
                        contentClassName="limit limit-300"
                        content="You can’t open the issue link as it might be deleted, done, or you don’t have an access to the board."
                    >
                        <Flex gap={2} grow>
                            {title}
                            <CustomIcon icon={CustomIconName.LOCKED} className="o-4 o-4--hover" />
                            {isDone ? <DoneIssueName name={name} /> : <span className="t-crop">{name}</span>}
                        </Flex>
                    </Tooltip>
                    {button}
                </StCardStripeHeader>
            </StCardStripe>
        );
    }

    const handleClick = (e) => {
        if (e.metaKey) return;
        e.preventDefault();
        if (issue.boardId !== issuesList.activeIssue.boardId) {
            navigate(issue.topLink);
            delay(300).then(() => issuesList.setActiveIssue(issue));
        } else {
            issuesList.setActiveIssue(issue);
        }
    };

    return (
        <StCardStripe as={Flex} gap={8}>
            <StCardStripeHeader as={Flex}>
                {isDone ? (
                    <Tooltip
                        contentClassName="limit limit-300"
                        content="You can’t open the issue link as it might be deleted, done, or you don’t have an access to the board."
                    >
                        <Flex gap={2} grow>
                            {title}
                            <CustomIcon icon={CustomIconName.LOCKED} className="o-4 o-4--hover" />
                            <DoneIssueName name={name} />
                        </Flex>
                    </Tooltip>
                ) : (
                    <Tooltip side="top" content={<IssueTooltip issue={issue} />} contentClassName="limit limit-300">
                        <Flex gap={2} grow>
                            {title}
                            <StLink onClick={handleClick} as={Link} className="t-crop" to={issue.topLink}>
                                {issue.fullName}
                            </StLink>
                        </Flex>
                    </Tooltip>
                )}
                {button}
            </StCardStripeHeader>
        </StCardStripe>
    );
}

export default observer(BlocksIssueItem);

import React from 'react';

import { observer } from 'mobx-react-lite';

import { EMPTY_ARRAY } from 'constants/atoms';

import { mainStore } from 'store/models/MainStore';

import { DICTIONARY_LABELS_VOTING } from 'utils/consts';
import logEvent from 'utils/logEvent';

import DictionaryMultiSelect from 'components/DictionaryValueSelect/DictionaryMultiSelect';

const DICTIONARY = DICTIONARY_LABELS_VOTING;

function AnnounceLabelsMultiselect({ announce }) {
    if (!announce) return null;

    async function onRemove(item) {
        logEvent(`Remove ${DICTIONARY} label from announce`);
        await announce.removeLabel(item);
    }

    async function onAdd(value) {
        logEvent(`Add ${DICTIONARY} label to announce`);
        await announce.addLabel(value);
    }

    async function onCreate(name) {
        logEvent(`Create ${DICTIONARY} label for announce`);
        const label = await mainStore.activeBoard.createDictItem({ dictionary: DICTIONARY, name });
        label && (await announce.addLabel(label));
    }

    return (
        <DictionaryMultiSelect
            dictionary={DICTIONARY}
            activeList={announce.labelsList || EMPTY_ARRAY}
            list={announce.board.getDictByName(DICTIONARY)}
            onRemove={onRemove}
            board={mainStore.activeBoard}
            onCreate={onCreate}
            onAdd={onAdd}
            btnProps={{ text: 'Add Labels' }}
        />
    );
}

export default observer(AnnounceLabelsMultiselect);

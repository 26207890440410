import { makeAutoObservable, action, computed, autorun, toJS } from 'mobx';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';

export class TableSizeStore {
    colSmartWidths = appStorage.get(APP_STORAGE_KEYS.columns_width) || {};
    tableKey = null;

    constructor(tableKey) {
        makeAutoObservable(this, { setWidths: action, setTableKey: action, widths: computed });

        this.setTableKey(tableKey);

        autorun(() => {
            appStorage.set(APP_STORAGE_KEYS.columns_width, toJS(this.colSmartWidths));
        });
    }

    setTableKey(tableKey) {
        this.tableKey = tableKey;
    }

    get widths() {
        return this.colSmartWidths[this.tableKey];
    }

    setWidths(widths) {
        this.colSmartWidths[this.tableKey] = widths;
    }
}

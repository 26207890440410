import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { AutoSizer, Column, Table } from 'react-virtualized';

import { Issue } from 'store/models/Issue';
import { issuesList } from 'store/models/IssuesList';
import { TableSizeStore } from 'store/models/TableSizeStore';

import { CURRENT_USER_ID, IS_PUBLIC_BOARD, ISSUE_CARD_POSITION } from 'utils/consts';

import EmptyLoader from 'components/EmptyLoader';
import IssueModal from 'components/IssueModal';
import styles from 'components/MasterTable/MasterTable.module.sass';

import BodyCell from './components/BodyCell';
import FreeSpace from './components/FreeSpace/FreeSpace';
import HeaderCell from './components/HeaderCell';
import { rowRenderer } from './components/rowRenderer';
import {
    calcFieldSize,
    getAnotherWidth,
    getColumnId,
    getColumnsHash,
    getSmartColumnWidth,
    getTableKey,
    updateTableWidth,
} from './widthUtils';

const ROW_HEIGHT = 32;
const emptyObj = {};
/**
 * Virtual scrollable table
 *
 * @param {Object} props
 * @param {boolean} [props.hideTop]
 * @param {boolean} [props.showHover]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.issueSplit]
 * @param {boolean} [props.disableNewIssue]
 * @param {boolean} [props.showTooltips]
 * @param {boolean} [props.listLoading]
 * @param {[Object]} [props.list]
 * @param {[Object]} [props.columns]
 * @param {JSX.Element} [props.firstColumn]
 * @param {JSX.Element} [props.modal]
 * @param {JSX.Element} [props.emptyState]
 * @param {Function} [props.onRowClick]
 * @param {number} [props.cardPosition]
 * @param {string} [props.tableName]
 * @param {string} [props.rowClassNames]
 * @param {string} [props.className]
 * @param {boolean} [props.fullSize]
 * @param {number} [props.scrollToIndex]
 * @param {Object} [props.rowProps]
 * @param {Object} [props.board]
 * @param {string} [props.scrollToAlignment]
 *
 * @returns {JSX.Element|null|*}
 */
function MasterTable({
    list = [],
    columns = [],
    disabled = false,
    issueSplit = false,
    showHover = false,
    hideTop = false,
    cardPosition = ISSUE_CARD_POSITION.RIGHT,
    disableNewIssue = false,
    firstColumn,
    ...props
}) {
    const [tableKey] = useState(() => {
        return getTableKey(props.tableName, props.board.id);
    });
    const [tableSizeStore] = useState(new TableSizeStore(tableKey));
    const [sizes, setSizes] = useState(tableSizeStore.widths);
    const activeIssue = issuesList.activeIssue;
    const tableRef = useRef();

    reaction(
        () => tableSizeStore.widths,
        (widths) => {
            setSizes(widths);
        },
    );

    useEffect(() => {
        let widths = tableSizeStore.colSmartWidths[tableKey] || getSmartColumnWidth(columns);
        const columnHash = getColumnsHash(columns);
        if (columnHash !== widths.hash) {
            const newWidths = updateTableWidth(widths.fields, columns);
            tableSizeStore.setWidths(newWidths);
        } else if (!tableSizeStore.colSmartWidths[tableKey]) {
            tableSizeStore.setWidths(widths);
        }
        return () => {};
    }, [columns, tableKey, tableSizeStore]);

    const onRowClick = useCallback(
        ({ event, index, rowData }) => {
            if (/bp5-button|bp5-control/.test(event.target.className)) {
                return;
            }

            if (!disabled && rowData instanceof Issue && !issuesList.idea?.hasUnsavedAI) {
                issuesList.changeFocus({ row: index, col: issuesList.col, activeIssue: rowData });
            }
        },
        [disabled],
    );

    if (!sizes) {
        return null;
    }

    const rowGetter = ({ index }) => {
        return list?.[index] ?? emptyObj;
    };

    const resizeRow = ({ col, deltaX }) => {
        const { fields, hash } = sizes;
        const column = columns[col];
        runInAction(() => {
            const newFields = fields.map((el) => {
                if (el.id === getColumnId(column)) {
                    el.width = Math.max(el.width + deltaX, 40);
                }
                return el;
            });
            const width = getAnotherWidth(fields);
            const newWidths = { fields: newFields, width, hash };
            tableSizeStore.setWidths({ ...newWidths, timestamp: Date.now() });
        });
    };

    const getTable = ({ height, width }) => {
        const { fields, width: stateWidth } = sizes;
        if (props.emptyState) {
            return (
                <div className="flex" style={{ height, width }}>
                    {props.emptyState}
                </div>
            );
        }

        const tableWidth = Math.max(stateWidth?.full || 0, width);
        let cnt = list.length;

        const activeIssueId = issuesList.activeIssue?.id;

        const issueIndexRow =
            activeIssueId && cnt > 0 ? list.findIndex((issue) => issue.id === activeIssueId) : issuesList.row;

        const indexRow = props.scrollToIndex ?? issueIndexRow;

        const showAddButton = !IS_PUBLIC_BOARD && CURRENT_USER_ID && !disableNewIssue && !disabled;

        const finalCount = showAddButton ? cnt + 1 : cnt;

        const scrollToAlignment = (props.scrollToAlignment ?? indexRow + 2 > cnt) ? 'center' : undefined;

        const boardView = props.board.view;

        const rowClasses = props.rowClassNames;
        const rowProps = props.rowProps;
        const needFocus = !props.onRowClick;

        const table = (
            <Table
                sort={props.board.useSort}
                overscanRowCount={2}
                sortBy={props.board.sortBy || boardView?.sortField}
                sortDirection={props.board.sortDirection || boardView?.sortDirection}
                width={tableWidth}
                height={height}
                headerHeight={ROW_HEIGHT}
                rowHeight={ROW_HEIGHT}
                rowCount={finalCount}
                scrollToIndex={indexRow !== -1 && indexRow !== null ? indexRow : undefined}
                rowGetter={rowGetter}
                rowRenderer={(props) => {
                    return rowRenderer(props, {
                        interactive: showHover || issueSplit,
                        forceTableNav: props.forceTableNav,
                        cnt,
                        rowClassNames: rowClasses,
                        needFocus,
                        rowProps,
                    });
                }}
                onRowClick={props.onRowClick || onRowClick}
                scrollToAlignment={scrollToAlignment}
                estimatedRowSize={undefined}
                onScroll={undefined}
                overscanIndicesGetter={undefined}
                rowStyle={undefined}
            >
                {firstColumn}
                {columns.map((column, index) => {
                    const columnId = getColumnId(column);
                    const colWidth = fields?.find((el) => el.id === columnId);
                    if (colWidth && 'row_number' === colWidth.id && colWidth.width < 40) {
                        colWidth.width = 40;
                    }

                    const classes = `cell--${columnId}`;
                    const headClasses = `headerCell--${columnId}`;

                    return (
                        <Column
                            key={index}
                            disableSort={column.disableSort}
                            headerRenderer={(cellProps) => (
                                <HeaderCell
                                    {...cellProps}
                                    sortType={boardView?.sortFieldType}
                                    resizeRow={resizeRow}
                                    showTooltips={props.showTooltips}
                                    disabled={disabled}
                                    index={index}
                                />
                            )}
                            dataKey={columnId}
                            width={(colWidth && colWidth.width) || column.width || calcFieldSize(column?.name ?? '')}
                            cellRenderer={({ columnData, columnIndex, dataKey, rowData, rowIndex, cellData }) => {
                                return (
                                    <BodyCell
                                        rowData={rowData}
                                        dataKey={dataKey}
                                        rowIndex={rowIndex}
                                        columnIndex={columnIndex}
                                        columnData={columnData}
                                        cellData={cellData}
                                        showTooltips={props.showTooltips}
                                        disabled={disabled}
                                        hideTop={hideTop}
                                    />
                                );
                            }}
                            style={column.style}
                            headerStyle={column.style}
                            headerClassName={`${styles[classes]} ${styles[headClasses]}`}
                            className={styles[classes]}
                            columnData={column}
                        />
                    );
                })}
            </Table>
        );

        if (props.modal) {
            return (
                <div className="flex">
                    {table}
                    {props.modal}
                </div>
            );
        }

        if (!activeIssue || disabled || cnt === 0) {
            return table;
        }

        return (
            <div className="flex">
                {table}
                <FreeSpace position={cardPosition} height={height} width={width - stateWidth?.full} />
                {props.modal || <IssueModal height={height} position={cardPosition} />}
            </div>
        );
    };

    if (props.listLoading || list === null || !props.board) {
        return EmptyLoader();
    }

    const classesTable = classNames(styles.table, props.className, {
        [styles.loading]: props.board.taskLoader,
        [styles.full]: props.fullSize,
    });

    return (
        <div className={classesTable} ref={tableRef}>
            <AutoSizer>{(props) => getTable(props)}</AutoSizer>
        </div>
    );
}

export default observer(MasterTable);

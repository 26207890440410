import api from 'api';

import { mainStore } from 'store/models/MainStore';

import debouncePromise from 'utils/debouncePromise';

let abortController;
export default debouncePromise(async (query) => {
    abortController?.abort();
    abortController = new AbortController();
    try {
        const { data } = await api.get(`${mainStore.activeBoard.apiEndpoint}/mentions`, {
            params: { q: query || '' },
            signal: abortController.signal,
        });
        return data;
    } catch {
        return [];
    }
}, 300);

import { makeAutoObservable, observable } from 'mobx';

import { EMPTY_ARRAY } from 'constants/atoms';

import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { IS_VOTING_BOARD } from 'utils/consts';

export default class Comment {
    assignee_id = 0;
    closed = null;
    created = '';
    id = 0;
    issue_id = null;
    message = '';
    messages = [];
    open = false;
    users = [];
    user = null;
    parent_id = null;
    author_id = null;
    idea;

    constructor(data, idea) {
        this.idea = idea;
        this.assignee_id = data.assignee_id;
        this.closed = data.closed;
        this.created = data.created;
        this.id = data.id;
        this.issue_id = data.issue_id || null;
        this.message = data.message;
        this.messages = data.messages || [];
        this.open = data.open;
        this.users = data.users;
        this.user = data.user || null;
        this.parent_id = data.parent_id;
        this.author_id = data.author_id || null;

        makeAutoObservable(this, { idea: false, users: observable.struct, messages: observable.struct });
    }

    get board() {
        return this.idea?.board;
    }

    get issue() {
        return this.idea || issuesList.allIssues.find((el) => el.id === this.issue_id);
    }

    update(data) {
        Object.assign(this, data);
    }

    get author() {
        if (this.author_id) {
            return mainStore.users.usersIds.get(this.author_id);
        }
        return this.user;
    }

    get authors() {
        if (this.author && this.user) {
            return [];
        }
        if (this.user) {
            return [{ user: this.user }];
        }
        return this.users
            .filter((user) => user.user_id !== this.author_id)
            .map((item) => {
                const user = mainStore.users.usersIds.get(item.user_id);
                return { resolved: item.resolved, user };
            });
    }

    get assignee() {
        if (IS_VOTING_BOARD) {
            return undefined;
        }
        const assignee_id = this?.assignee_id;
        return assignee_id ? mainStore.users.usersIds.get(assignee_id) : undefined;
    }

    get canEdit() {
        if (!IS_VOTING_BOARD && this.idea) {
            return this.board.currentUserInBoard && this.open;
        }
        if (this.issue instanceof Idea) {
            return this.user?.id === mainStore.currentUser?.voting_user_id;
        }
        return false;
    }

    get joinCount() {
        if (this.user) {
            return 0;
        }
        return this.users.length - 1;
    }

    get childComments() {
        if (this.parent_id === undefined) {
            return this.messages;
        }
        if (!this.issue) return EMPTY_ARRAY;
        return this.issue.requests.filter((request) => request.parent_id === this.id);
    }

    get canJoin() {
        if (!this.users || this.users.findIndex((item) => item.user_id === mainStore.currentUser?.id) !== -1) {
            return false;
        }
        return this.assignee_id !== mainStore.currentUser?.id;
    }
}

import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { mainStore } from 'store/models/MainStore';
import { providerForm } from 'store/models/ProviderForm';

import { IS_PUBLIC_BOARD, PAYWALL_LIMITS, SERVICE_NAME } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import FakePushToTracker from 'components/FakePushToTracker';
import Kbd, { KeyCombo } from 'components/Kbd';
import PlatformIcon from 'components/PlatformIcon';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

function PlatformAction({ issue }) {
    if (IS_PUBLIC_BOARD || mainStore.currentUser?.isViewer) return null;

    function initPushForm() {
        logEvent('Click Push to Tracker', { provider: issue.board.provider });
        providerForm.togglePushIssue(issue);
    }

    if (mainStore.currentUser?.isAdmin && issue.board.provider === PROVIDER_DUCALIS) {
        return <FakePushToTracker boardId={issue.boardId} />;
    }

    if (issue.provider !== PROVIDER_DUCALIS || !issue.board?.canSendToPlatform) return null;

    const pushToButtonProps = {
        icon: CustomIconName.ARROW_RIGHT,
        minimal: true,
        text: (
            <>
                Push to <span className="t-cap">{issue.board.provider}</span>
            </>
        ),
        block: true,
        rightElm: <PlatformIcon provider={issue.board.provider} />,
    };

    if (!mainStore.organization.canPushToTracker) {
        return (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.PUSH_ISSUE} text="to Push to Task Tracker">
                <Button disabled {...pushToButtonProps} />
            </UpgradePlanBtn>
        );
    }

    if (issue.board.needNewOwner && !issue.board.error) {
        return (
            <DarkTooltip position="left" content="The board must have an owner" fast>
                <Button disabled {...pushToButtonProps} />
            </DarkTooltip>
        );
    }

    return (
        <Tooltip side="left" content={`${SERVICE_NAME} task. You can push it to the tracker`}>
            <Button
                className="bp5-popover-dismiss"
                data-place="push-to-tracker"
                {...pushToButtonProps}
                onClick={initPushForm}
                disabled={issue.id === -1}
                rightElm={
                    <KeyCombo>
                        <Kbd>P</Kbd>
                    </KeyCombo>
                }
            />
        </Tooltip>
    );
}

export default observer(PlatformAction);

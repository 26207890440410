import React from 'react';

import { observer } from 'mobx-react-lite';

import { FILTER_TYPES } from 'utils/consts';

import CellWrapper from 'components/CellWrapper';
import { prepareValueForCellView } from 'components/MasterTable/components/prepareValueForCellView';

const CompanyCustomFieldCell = ({ field, item, value }) => {
    const fieldValue = item.company?.custom_fields?.[field];

    if (fieldValue == null) {
        return <CellWrapper />;
    }

    const valueCell = prepareValueForCellView(fieldValue, value);
    return (
        <CellWrapper isNumber={value.type === FILTER_TYPES.INT}>
            <span className="bp5-text-overflow-ellipsis">{valueCell}</span>
        </CellWrapper>
    );
};

export default observer(CompanyCustomFieldCell);

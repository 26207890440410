import { DateTime } from 'luxon';
import { makeAutoObservable, observable, runInAction } from 'mobx';

import api, { storageApi } from 'api';
import { THEMES, THEMES_IDS } from 'themes';

import * as OrganizationApi from 'store/models/api/Organization';
import { mainStore } from 'store/models/MainStore';

import { EXPORT_ACCESS, INSTANCE_PREFIX, IS_VOTING_BOARD, PAYWALL_LIMITS, USER_ROLE } from 'utils/consts';
import failRequest from 'utils/failRequest';
import getCurrencySymbolByCode from 'utils/getCurrencySymbolByCode';
import logEvent from 'utils/logEvent';

export default class Organization {
    id = null;
    name = '';
    timezone = '';
    export_access = null;
    typeform_response = null;
    payment_subscription = null;
    last_updated = '';
    _links = null;
    platforms = [];
    inviteUsers = [];
    inviteUsersLoading = false;
    invite_type = null;
    domains = [];
    voting_email_settings = null;
    ideas_bots_settings = null;
    public_voting_settings = (IS_VOTING_BOARD && window.votingSetting) || null;
    has_admin_access = false;
    invite_role = USER_ROLE.Member;
    next_email_limit_reset = false;
    insights_enabled = false;
    created = '';
    webhooks = [];
    owner_id = null;

    deal_paid_users = 0;
    deal_paid_viewers = 0;
    deal_next_billing_cycle_date = '';

    has_payment_methods = false;

    openWebHook = null;

    votingEmailStats = false;

    constructor(data) {
        makeAutoObservable(this, {
            voting_email_settings: observable.struct,
            payment_subscription: observable.struct,
            public_voting_settings: observable.struct,
            _links: observable.struct,
            webhooks: observable.struct,
            domains: observable.struct,
        });

        data && this.updateModel(data);
    }

    get publicName() {
        return this.public_voting_settings?.publicName || this.name || '';
    }

    get language() {
        if (!IS_VOTING_BOARD) {
            return 'en';
        }
        return this.languageSettings;
    }

    get languageSettings() {
        return this.public_voting_settings?.language ?? window.defaultLanguage ?? 'en';
    }

    get canFree() {
        return this.paymentStatus === 'trial';
    }

    /**
     * @return {THEMES_IDS}
     */
    get theme() {
        return this.public_voting_settings?.theme || window.theme || THEMES_IDS.dark;
    }

    get isDarkTheme() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.theme !== 2;
    }

    /**
     * @return {string}
     */
    get themeName() {
        return THEMES.get(this.theme)?.name ?? '';
    }

    get ducalisBranding() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.public_voting_settings?.ducalis_branding ?? true;
    }

    get logo() {
        return this.public_voting_settings?.logo || this.public_voting_settings?.smallLogo;
    }

    updateModel(fields) {
        Object.assign(this, fields);
    }

    canUserUseCsvExport(user) {
        switch (this.export_access) {
            case EXPORT_ACCESS.owner:
                return user.is_owner;
            case EXPORT_ACCESS.admins:
                return user.role === USER_ROLE.Admin;
            case EXPORT_ACCESS.admins_members:
                return user.role !== USER_ROLE.Viewer;
            default:
                return false;
        }
    }

    get date_updated() {
        return DateTime.fromISO(this.last_updated).toFormat('ff');
    }

    get paymentStatus() {
        return this.payment_subscription?.status || null;
    }

    get paymentRequired() {
        return ['trial_ended', 'cancelled', 'stopped'].indexOf(this.paymentStatus) !== -1;
    }

    get hideTrialBanner() {
        return this.paymentStatus !== 'trial';
    }

    get payments() {
        return {
            payment_status: this.paymentStatus,
            payment_plan: this.paymentPlanKey,
            payment_required: this.payment_required,
            trial_days_left: this.trialDaysLeft,
            trial_end: this.payment_subscription?.trial_end,
            trial_start: this.payment_subscription?.trial_start,
        };
    }

    get trialDaysLeft() {
        return this.payment_subscription?.trial_days_left || null;
    }

    get paymentPlan() {
        return this.payment_subscription?.plan || null;
    }

    get emailPlan() {
        return this.voting_email_settings?.plan_id || null;
    }

    get paymentQuantity() {
        return mainStore.users.activeUsersWithoutViewers?.length || 1;
    }

    get paymentPlanKey() {
        return this.paymentPlan?.key;
    }

    get paymentPlanName() {
        return this.paymentPlan?.name;
    }

    get paymentPlanLimits() {
        return (
            this.paymentPlan?.paywalls.filter((el) => el.value === null || el.value === true).map((el) => el.key) || []
        );
    }

    hasPaymentPlan(limit) {
        if (this.paymentRequired) {
            return false;
        }
        return this.paymentPlanLimits.includes(limit);
    }

    get canVotingDomain() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_DOMAIN);
    }

    get canVotingAppearance() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_APPEARANCE);
    }

    get canPushToTracker() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.PUSH_ISSUE);
    }

    get needBlockBoard() {
        return this.paymentRequired;
    }

    get needBlockInvite() {
        if (this.paymentRequired) {
            return true;
        }
        const limit = this.paymentPlan?.[PAYWALL_LIMITS.USERS_LIMIT];
        if (limit === null) {
            return false;
        }
        return mainStore.limits?.[PAYWALL_LIMITS.USERS_LIMIT] >= limit;
    }

    get currentPlanId() {
        return (this.paymentPlan && this.paymentPlan.id) || null;
    }

    get currency() {
        return getCurrencySymbolByCode(this.paymentPlan?.currency);
    }

    get extraBillingPage() {
        if (!this.payment_subscription) {
            return false;
        }
        return this.has_payment_methods || ['paying', 'stopped'].indexOf(this.paymentStatus) !== -1;
    }

    get votingLink() {
        return `https://${mainStore.organization.votingDomain}`;
    }

    get votingDomain() {
        if (this.public_voting_settings.is_subdomain) {
            return `${this.public_voting_settings.subdomain}${INSTANCE_PREFIX ? '-' : '.'}${window.location.hostname}`;
        }
        return this.public_voting_settings.custom_domain;
    }

    updateSettings(obj) {
        Object.entries(obj).forEach(([key, value]) => {
            this[key] = value;
        });
        this.pushChangeToServer(obj);
    }

    setName(name, save = false) {
        if (this.name !== name) {
            this.name = name;
        }
        if (save) {
            logEvent('CHANGE_ORG_NAME', { name: this.name });
            this.pushChangeToServer({ name: this.name });
        }
    }

    setTimezone = (timezone) => {
        if (timezone && this.timezone !== timezone) {
            this.timezone = timezone;
            this.pushChangeToServer({ timezone });
        }
    };

    setInviteType = (invite_type) => {
        if (invite_type && this.invite_type !== invite_type) {
            logEvent('Change org invite type', { invite_type });
            this.invite_type = invite_type;
            this.pushChangeToServer({ invite_type });
        }
    };

    changeAccountAccess() {
        const has_admin_access = !this.has_admin_access;
        this.has_admin_access = has_admin_access;
        logEvent('Change Account Access', { has_admin_access });
        this.pushChangeToServer({ has_admin_access });
    }

    // Api methods list

    fetchOrganization = async () => {
        try {
            const { data } = await storageApi.get(`/organization`);
            this.updateModel(data);
        } catch (e) {
            failRequest(e);
        }
    };

    getVotingEmailsStats = async () => {
        try {
            const { data } = await api.get(`/organization/voting-email-stats`);
            runInAction(() => {
                this.votingEmailStats = data;
            });
        } catch (e) {}
    };

    setDomain = OrganizationApi.setDomain;

    changeOrg = OrganizationApi.changeOrg;

    pushChangeToServer = OrganizationApi.pushChangeToServer;

    pullInviteUsers = OrganizationApi.pullInviteUsers;

    sendInviteUsers = OrganizationApi.sendInviteUsers;

    addDomain = OrganizationApi.addDomain;

    deleteLogo = OrganizationApi.deleteLogo;

    changeLogo = OrganizationApi.changeLogo;

    changePublicName = OrganizationApi.changePublicName;

    setColor = OrganizationApi.setColor;

    removeDomain = OrganizationApi.removeDomain;

    setVotingSettings = OrganizationApi.setVotingSettings;

    setVotingEmailSettings = OrganizationApi.setVotingEmailSettings;

    runDnsCheck = OrganizationApi.runDnsCheck;

    removeAll = OrganizationApi.removeAll;

    getSecret = OrganizationApi.getSecret;

    setWebhook = OrganizationApi.setWebhook;

    getWebhookDate = OrganizationApi.getWebhookDate;

    deleteWebhook = OrganizationApi.deleteWebhook;

    setBotSettings = OrganizationApi.setBotSettings;
}

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { InputGroup, useHotkeys } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { defineMessages, useIntl } from 'react-intl';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { mainStore } from 'store/models/MainStore';
import screenStore from 'store/models/ScreenStore';

import { IS_VOTING_BOARD, VIEWS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DotDivider from 'components/DotDivider';
import Kbd from 'components/Kbd';
import { TableSearchClearButton } from 'components/TableSearch/TableSearchClearButton';
import Tooltip from 'components/Tooltip';

import styles from './TableSearch.module.sass';

function TableSearch({ query, onSearch, className, clear, tooltip, size = 24, small, large }) {
    const isIdeas = IS_VOTING_BOARD || mainStore.page === VIEWS.IDEAS;
    const [show, setShow] = useState(false);
    const inputRef = useRef();
    const intl = useIntl();
    const disabled = settingsStore.isOpen;

    const message = isIdeas
        ? defineMessages({
              id: 'title.idea-name-search',
              defaultMessage: 'Search by Idea name',
          })
        : defineMessages({
              id: 'title.issue-name-search',
              defaultMessage: 'Search by Issue name',
          });

    const titleIntl = tooltip || intl.formatMessage(message);

    useEffect(() => {
        return () => onSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        !show && onSearch && onSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    const hotkeys = useMemo(() => {
        const list = [
            {
                combo: '/',
                global: true,
                disabled: disabled,
                label: titleIntl,
                group: 'Table',
                onKeyUp: () => {
                    setShow((state) => !state);
                },
            },
        ];

        if (show) {
            list.push({
                combo: 'Esc',
                label: 'Close',
                group: titleIntl,
                preventDefault: true,
                stopPropagation: true,
                allowInInput: true,
                onKeyDown: () => setShow(false),
            });
        }
        return list;
    }, [show, titleIntl, disabled]);

    const { handleKeyDown } = useHotkeys(hotkeys);

    if (typeof onSearch !== 'function') {
        return null;
    }

    function clearSearch() {
        onSearch('');
        inputRef.current?.focus();
    }

    if (show) {
        const inputClasses = classNames(styles.input, {
            [styles.inputMobile]: screenStore.isMobile,
            [styles.clear]: clear,
            [styles.large]: large,
            [styles.small]: small,
        });

        const classes = classNames(styles.tableSearch, {
            'p-r': !clear,
            [styles.mobile]: screenStore.isMobile,
            [styles.voting]: IS_VOTING_BOARD,
        });

        return (
            <div className={classes}>
                <div className={inputClasses}>
                    <InputGroup
                        onKeyDown={handleKeyDown}
                        inputRef={inputRef}
                        fill
                        autoFocus
                        leftIcon={<CustomIcon className={styles.icon} icon={CustomIconName.SEARCH} />}
                        rightElement={<TableSearchClearButton query={query} onClick={clearSearch} />}
                        onChange={({ target }) => onSearch(target.value)}
                        placeholder={titleIntl}
                        value={query}
                        onBlur={() => query < 2 && setShow(false)}
                    />
                </div>
            </div>
        );
    }

    const button = (
        <Button
            border
            className={className}
            data-place="table-search"
            icon={CustomIconName.SEARCH}
            size={size}
            minimal
            onClick={() => setShow(true)}
        />
    );

    if (screenStore.isMobile) {
        return <div>{button}</div>;
    }

    return (
        <Tooltip
            content={
                <div className="t-nw">
                    {titleIntl}
                    <DotDivider />
                    <Kbd>/</Kbd>
                </div>
            }
        >
            {button}
        </Tooltip>
    );
}

export default observer(TableSearch);

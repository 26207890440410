import { makeAutoObservable, toJS } from 'mobx';

import { dialogCriteriaFormStore } from 'store/models/DialogCriteriaForm';
import { mainStore } from 'store/models/MainStore';
import { collectionUtils } from 'store/models/utils/collectionsUtils';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import uniqBy from 'utils/uniqBy';

class CriteriaStore {
    criteriaIds = new Map();
    criteriaBoardsIds = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    fillData(criteria, criteriaBoards) {
        collectionUtils.updateMap(this.criteriaIds, criteria);
        collectionUtils.updateMap(this.criteriaBoardsIds, criteriaBoards);
    }

    get criteriaBoards() {
        return Array.from(this.criteriaBoardsIds.values());
    }

    get criteria() {
        return Array.from(this.criteriaIds.values());
    }

    get sortedCriteria() {
        return this.criteria.slice().sort((a, b) => {
            if (a.name === b.name) {
                return this.boardsByCriteria[b.id]?.length - this.boardsByCriteria[a.id]?.length;
            }
            return a.name > b.name ? 1 : -1;
        });
    }

    get criteriaByBoards() {
        return this.criteriaBoards.reduce((res, el) => {
            if (!res[el.board_id]) {
                res[el.board_id] = [];
            }
            res[el.board_id].push(el.criterion_id);
            return res;
        }, {});
    }

    get boardsByCriteria() {
        return this.criteriaBoards.reduce((res, el) => {
            if (!res[el.criterion_id]) {
                res[el.criterion_id] = [];
            }
            res[el.criterion_id].push(el.board_id);
            return res;
        }, {});
    }

    updateCriteria(data) {
        if (data.archived) return; // ignore archived criteria

        const criteria = this.criteriaIds.get(data.id);
        if (criteria) {
            Object.assign(criteria, data);
            mainStore.db.updateCurrentRowById(data, ORG_DB_STORES.criteria);
        } else if (data.organization_id && data.archived === false) {
            this.criteriaIds.set(data.id, data);
            mainStore.db.updateRowDB(data, ORG_DB_STORES.criteria);
        }
    }

    removeCriteria(criterionId) {
        this.criteriaIds.delete(criterionId);
    }

    addCriteria(data) {
        this.criteriaIds.set(data.id, data);
    }

    updateBoardCriteria(data) {
        if (!this.criteriaBoardsIds.has(data.id)) return;

        const criteria = this.criteriaBoardsIds.get(data.id);
        Object.assign(criteria, data);

        if (dialogCriteriaFormStore.criterion?.id === criteria.id) {
            dialogCriteriaFormStore.setCriterion(criteria);
        }

        mainStore.db.updateRowDB(toJS(criteria), ORG_DB_STORES.criteriaBoards);
    }

    removeBoardCriteria(criterionBoardId) {
        this.criteriaBoardsIds.delete(criterionBoardId);
    }

    addBoardCriteria(data) {
        this.criteriaBoardsIds.set(data.id, data);
    }

    getBoardsNamesByCriterionId(criterionId) {
        const boardsCriteria = this.criteriaBoards.filter((el) => el.criterion_id === criterionId);

        return boardsCriteria.reduce((res, criterion) => {
            const board = mainStore.boardsList.boardsIds.get(criterion.board_id);
            if (board) {
                res[criterion.board_id] = board.fullName;
            } else if (typeof res[0] === 'undefined') {
                res[0] = 1;
            } else {
                res[0] += 1;
            }
            return res;
        }, {});
    }

    getSingleBoardByCriterionId(criterionId) {
        const boardId = this.boardsByCriteria[criterionId]?.[0];
        return boardId && mainStore.boardsList.boardsIds.get(boardId);
    }

    getBoardsIdsByBoardsCriteria(boardsCriteria) {
        if (boardsCriteria[0].criterion_id) {
            return uniqBy(boardsCriteria, (el) => el.board_id);
        }
        const boardIds = boardsCriteria.map((criterion) =>
            mainStore.activeBoards.filter((el) => el.criteriaIds.includes(criterion.id)).map((board) => board.id),
        );
        return Array.from(new Set(boardIds.flat()).values());
    }
}

export default new CriteriaStore();

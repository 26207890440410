import { DateTime } from 'luxon';

import { FILTER_TYPES } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

export function prepareValueForCellView(value, column) {
    if (value == null) {
        return null;
    }

    if ([FILTER_TYPES.LIST].includes(column.type)) {
        if (typeof value.map !== 'function') {
            return String(value);
        }
        return value.map((el) => el).join(`, \n`);
    }
    if (FILTER_TYPES.USER === column.type) {
        return value?.name ?? value;
    }
    if (FILTER_TYPES.USER_LIST === column.type) {
        if (!Array.isArray(value)) {
            return value;
        }
        return value
            .map((el) => el?.name ?? el)
            .filter((el) => el)
            .join(', ');
    }
    if (column.type === FILTER_TYPES.CHECKBOX) {
        return <CustomIcon className="o-4" icon={value ? CustomIconName.PLUS : CustomIconName.MINUS} />;
    }
    if (column.type === FILTER_TYPES.DATETIME) {
        return value && DateTime.fromISO(value).toFormat('ff');
    }
    if (column.type === FILTER_TYPES.DATE) {
        if (value) {
            const isISOTime = /\dT\d/.test(value);
            const dateTime = isISOTime ? DateTime.fromISO(value) : DateTime.fromSQL(value);
            return dateTime.toFormat('DD');
        }
        return value;
    }
    if (typeof value === 'object') {
        return value && JSON.stringify(value);
    }
    return value;
}

import React from 'react';

import { Tooltip } from '@blueprintjs/core';

import screenStore from 'store/models/ScreenStore';

import { IS_IFRAME_WIDGET } from 'utils/consts';

/**
 * @inherit Tooltip
 */
export default function DarkTooltip(props) {
    if (!props.children) return null;
    if (!props.content || (screenStore.isMobile && !IS_IFRAME_WIDGET)) return props.children;

    return (
        <Tooltip
            position="bottom"
            minimal
            openOnTargetFocus={false}
            hoverOpenDelay={props.fast ? 0 : 300}
            boundary="window"
            {...props}
        />
    );
}

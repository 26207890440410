import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_CLICKUP, PROVIDER_GITHUB, PROVIDER_TRELLO } from 'constants/Providers';

import Members from 'modules/InsideIssue/components/Members';

import { issuesList } from 'store/models/IssuesList';

import MetaItem from 'components/MetaItem';
import UserBlock from 'components/UserBlock';

const Assignee = observer(({ issue, editable }) => {
    if (issue.membersList) {
        return (
            <Members
                tooltip={([PROVIDER_CLICKUP, PROVIDER_GITHUB].includes(issue.provider) && 'Assigned to') || 'Members'}
                membersList={issue.membersList}
            />
        );
    }
    return <UserBlock issue={issue} field="assignee" editable={editable} user={issue.assignee} />;
});

function AssigneeElm() {
    const issue = issuesList.issue;

    return (
        <MetaItem
            title={
                issue.provider === PROVIDER_TRELLO
                    ? 'Members'
                    : [PROVIDER_CLICKUP, PROVIDER_GITHUB].includes(issue.provider)
                      ? 'Assigned to'
                      : 'Assignee'
            }
        >
            <Assignee issue={issue} editable={issue?.isEditable} />
        </MetaItem>
    );
}

export default observer(AssigneeElm);

import { action, computed, makeAutoObservable } from 'mobx';

import { EMPTY_ARRAY } from 'constants/atoms';
import { logEvent } from 'utils';

import * as UserApi from 'store/models/api/User';
import { utilsStore } from 'store/models/UtilsStore';

import { CURRENT_USER_ID, IS_VOTING_BOARD, USER_ROLE } from 'utils/consts';
import delay from 'utils/delay';
import { uuidv4 } from 'utils/uuidv4';

import { mainStore } from './MainStore';

export class User {
    uuid = null;
    id = null;
    voting_user_id = null;
    session_id = null;
    has_fake_email = false;
    name = '';
    avatar = null;
    email = null;
    role = null;
    release_note_subscribed_boards = [];

    /**
     * Show Admin buttons for Voting Board Users
     *
     * @type {boolean}
     */
    can_manage = false;

    /**
     * @type {boolean}
     */
    is_banner_closed = true;
    is_owner = null;
    last_activity = null;
    invited = null;
    platformIDs = [];
    organizations = [];
    status = null;
    removed = false;
    tokens = [];
    event_11_issues_sent = true;
    closed_notes = [];
    board_subscriptions = [];
    bot_users = EMPTY_ARRAY;
    opt_in = null;

    socket;

    constructor(data) {
        makeAutoObservable(this, {
            socket: false,

            setName: action,
            setUser: action,
            pushChangeToServer: action,
            setId: action,

            platforms: computed,
            isAdmin: computed,
            isViewer: computed,
            isMember: computed,
        });

        this.uuid = uuidv4();

        data && this.fillModel(data);
    }

    fillModel({ organizations, closed_notes, ...fields }) {
        Object.assign(this, fields);

        if (closed_notes) {
            this.closed_notes = closed_notes;
        }

        if (organizations) this.organizations = organizations;
    }

    setName(name, save) {
        this.name = name;
        save && this.pushChangeToServer({ name });
    }

    setId(id) {
        if (!this.id || this.id === -1) {
            this.id = id;
        }
    }

    updateProperty(objField) {
        Object.entries(objField).forEach(([field, value]) => {
            this[field] = value;
        });
        this.pushChangeToServer(objField);
    }

    checkOptIn = () => {
        if (
            !IS_VOTING_BOARD ||
            this.opt_in !== null ||
            mainStore.organization.voting_email_settings?.can_send !== false
        )
            return;

        logEvent('Show user opt-in');

        delay(100).then(() => {
            utilsStore.toggleVotingOptIn(true);
        });
    };

    get discordBot() {
        return this.bot_users?.find((bot) => bot.provider === 'discord');
    }

    get telegramBot() {
        return this.bot_users?.find((bot) => bot.provider === 'telegram');
    }

    get slackBot() {
        return this.bot_users?.find((bot) => bot.provider === 'slack');
    }

    get platforms() {
        return mainStore.platformsList.platforms.filter((platform) => this.platformIDs.indexOf(platform.id) !== -1);
    }

    get isAdmin() {
        return this.role === USER_ROLE.Admin;
    }
    get isViewer() {
        if (IS_VOTING_BOARD) return false;

        return this.role === USER_ROLE.Viewer;
    }
    get isMember() {
        if (IS_VOTING_BOARD) return false;

        return this.role === USER_ROLE.Member;
    }

    get theme() {
        return CURRENT_USER_ID === this.id ? utilsStore.settings.theme : 1;
    }

    get showMegaVideoBanner() {
        return this.isAdmin && !this.is_banner_closed;
    }

    // API methods

    pushChangeToServer = UserApi.pushChangeToServer;

    changeAvatar = UserApi.changeAvatar;

    getTokens = UserApi.getTokens;

    revokeToken = UserApi.revokeToken;

    generateToken = UserApi.generateToken;

    send11IssuesDone = UserApi.send11IssuesDone;

    closeNote = UserApi.closeNote;

    subscribeOnReleaseNote = UserApi.subscribeOnReleaseNote;

    unlinkBot = UserApi.unlinkBot;
}

import React from 'react';

import Markdown from 'markdown-to-jsx';

import { EMPTY_ARRAY } from 'constants/atoms';
import { PROVIDER_ASANA, PROVIDER_JIRA, PROVIDER_TRELLO, PROVIDER_YT } from 'constants/Providers';

import asanaParser from 'utils/asanaParser';
import jiraImageRegexp from 'utils/jiraImageRegexp';
import JiraToMd from 'utils/JiraToMd';
import trelloToMd from 'utils/trelloToMd';
import ytParser from 'utils/ytParser';

import ImagePreview from 'components/ImagePreview';
import ImageError from 'components/IssueDescription/components/ImageError';
import { SimpleImg } from 'components/IssueDescription/components/SimpleImg';
import Loom from 'components/Loom';
import NoneJiraAttachments from 'components/NoneJiraAttachments';

import { AttachLink, CodeBl, ColorPanel, ColorText, ImgThumb, Monosnap, User, VideoElm } from './components';

export default class PlatformDescription extends React.PureComponent {
    constructor(props) {
        super(props);
        const state = this.getState(props);

        this.state = {
            selectedIndex: 0,
            modalIsOpen: false,
            ...state,
        };
    }

    getState = (props) => {
        const { attachments, provider } = props;
        const images = [];
        const unUsedImages = [];
        let content;

        const description = String(props.description || '');

        switch (provider) {
            case PROVIDER_JIRA:
                content = description
                    // Safe for <string>
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;');

                if (attachments && attachments.length) {
                    content = attachments.reduce((str, img) => {
                        const regexp = jiraImageRegexp(img.filename);
                        if (str.match(regexp)) {
                            if (/\.mp4|\.mov/.test(img.filename.toLowerCase())) {
                                return str.replace(regexp, `<VideoElm src="${img.content}" />`);
                            } else if (
                                !/\.jpg|\.bmp|\.png|\.tiff|\.gif|\.jpeg|\.webp/.test(img.filename.toLowerCase())
                            ) {
                                return str.replace(regexp, `<FileLink file="${img.filename}" link="${img.content}" />`);
                            }
                            images.push({ source: img.content });
                            const index = images.length - 1;
                            return str.replace(
                                regexp,
                                `<ImgPreview file="${img.filename}" index="${index}" thumbnail="${img.content}" />`,
                            );
                        } else {
                            unUsedImages.push(img);
                        }
                        return str;
                    }, content);
                }
                return { description: JiraToMd(content), images, unUsedImages };

            case PROVIDER_TRELLO:
                return { description: trelloToMd(description), images };

            case PROVIDER_ASANA:
                return { description: asanaParser(description), images };

            case PROVIDER_YT:
                return { description: ytParser(description), images };

            default:
                return { description, images };
        }
    };

    getImage = ({ index, thumbnail, file }) => {
        return (
            <ImgThumb
                file={file}
                index={index}
                thumbnail={thumbnail}
                onClick={(i) => {
                    this.setState({ modalIsOpen: true, selectedIndex: i });
                }}
            />
        );
    };

    fileLink = ({ link, file }) => {
        return <AttachLink url={link} text={file} />;
    };

    ImgYt = ({ name, alt, error }) => {
        if (error) {
            return (
                <ImageError
                    error={`The file is quite large as it's encoded in base64. \nWe're working on updates to enable its display in the future.`}
                />
            );
        }
        const attachment = this.props.attachments.find((el) => el.name === name);
        if (!attachment) {
            return null;
        }
        if (attachment.type === 'video') {
            return <VideoElm key={attachment.name} src={attachment.url} />;
        }
        return <SimpleImg src={attachment.url} alt={alt} />;
    };

    getUser = (props) => {
        const platformUsers = this.props.issue.board?.platform?.platformUsers;
        return <User {...props} platformUsers={platformUsers || EMPTY_ARRAY} />;
    };

    table = (props) => {
        return (
            <div className="b-oa">
                <table
                    {...props}
                    className="bp5-html-table bp5-html-table--fixedleft bp5-html-table--oneline-th bp5-html-table--desc"
                />
            </div>
        );
    };

    componentDidUpdate(props) {
        if (this.props.description !== props.description) {
            this.setState(this.getState(this.props));
        }
    }

    render() {
        const { images, description, unUsedImages } = this.state;
        const { provider } = this.props;

        return (
            <>
                <div tabIndex={(this.props.editable && '0') || '-1'} className="typography typography--platform">
                    <Markdown
                        options={{
                            overrides: {
                                Monosnap: { component: Monosnap },
                                Loom: { component: Loom },
                                ImgPreview: { component: this.getImage },
                                FileLink: { component: this.fileLink },
                                User: { component: this.getUser },
                                a: { props: { target: '_blank' } },
                                ColorText: { component: ColorText },
                                ColorPanel: { component: ColorPanel },
                                CodeBl: { component: CodeBl },
                                ImgYt: { component: this.ImgYt },
                                VideoElm: { component: VideoElm },
                                SimpleImage: { component: SimpleImg },
                                img: { component: SimpleImg },
                                table: { component: this.table },
                            },
                        }}
                    >
                        {description}
                    </Markdown>
                </div>

                <ImagePreview
                    isOpen={this.state.modalIsOpen}
                    selectedIndex={this.state.selectedIndex}
                    images={images}
                    onClose={() => this.setState({ modalIsOpen: false, selectedIndex: 0 })}
                />

                {unUsedImages?.length > 0 && (
                    <NoneJiraAttachments small attachments={unUsedImages} provider={provider} />
                )}
            </>
        );
    }
}

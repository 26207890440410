import React, { useCallback, useRef, useState } from 'react';

import { Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';

import { itemRenderer } from 'modules/PublicIdeaStripe/components/AddHandleVote/itemRenderer';

import { POPOVER_PROPS_DIV } from 'utils/consts';
import debounce from 'utils/debounce';
import { isEmail } from 'utils/validators';

import Avatar from 'components/Avatar';
import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import VirtuosoList from 'components/VirtuosoList';

import styles from './IdeaAuthor.module.sass';

function activeItemIndex(list, active) {
    return list.findIndex(({ email }) => email === active?.email);
}

const createNewItemFromQuery = (query) => ({ id: -1, email: query, name: query });

const CreateNewItemUseFull = ({ query, onClick, active }) => {
    return (
        <Button
            disabled={!isEmail(query)}
            minimal
            block
            tabIndex="0"
            active={active}
            icon={CustomIconName.PLUS}
            text={`Add: "${query}"`}
            onClick={onClick}
            rightElm={!isEmail(query) ? <span className="t-err">invalid email</span> : undefined}
        />
    );
};

function IdeaAuthorSuggest({ idea, desc }) {
    const abortControllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState(false);
    const [wait, setWait] = useState(false);
    const [list, setList] = useState([]);
    const [query, setQuery] = useState('');

    async function changeAuthor(newAuthor) {
        clear();
        try {
            setLoading(true);
            await idea.changeAuthor(newAuthor.email);
        } finally {
            setLoading(false);
        }
    }

    const searchRequest = useCallback(async (query) => {
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        setWait(true);

        const data = await idea.board.suggestVoters(query, abortControllerRef);

        if (data) {
            setWait(false);
            setList(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchRequestDebounced = useCallback(
        debounce((query) => searchRequest(query), 400),
        [],
    );

    const onQueryChange = useCallback(
        (query) => {
            setQuery(query);
            searchRequestDebounced(query);
        },
        [searchRequestDebounced],
    );

    async function onItemSelect(item) {
        if (item.id === idea.author.id) return;
        await changeAuthor(item);
    }

    function clear() {
        setQuery('');
    }

    return (
        <Select
            itemListRenderer={(props) => (
                <VirtuosoList
                    width={328}
                    className="hide-v-scroll"
                    {...props}
                    sizeItem={30}
                    activeItemIndex={activeItemIndex}
                    noResults={
                        <Button
                            minimal
                            block
                            disabled={true}
                            text={!query ? 'Start typing for suggestions' : 'No results.'}
                        />
                    }
                />
            )}
            popoverProps={{
                ...POPOVER_PROPS_DIV,
                minimal: true,
            }}
            items={list}
            query={query}
            onQueryChange={onQueryChange}
            itemRenderer={itemRenderer}
            onItemSelect={onItemSelect}
            createNewItemRenderer={(query, active, onItemSelect) => (
                <CreateNewItemUseFull active={active} query={query} onClick={onItemSelect} />
            )}
            createNewItemFromQuery={createNewItemFromQuery}
            inputProps={{
                placeholder: 'Search by name or email...',
                leftElement: (
                    <Flex center style={{ height: 30, width: 30 }}>
                        <CustomIcon className="t-mutted" icon={CustomIconName.SEARCH} />
                    </Flex>
                ),
                rightElement:
                    query.length >= 1 ? (
                        <Flex center style={{ height: 30, width: 30 }}>
                            <Button loading={wait} size={24} minimal icon={CustomIconName.CROSS_M} onClick={clear} />
                        </Flex>
                    ) : undefined,
            }}
        >
            <Button
                disabled={loading}
                size={24}
                border
                iconSize={14}
                rightIcon={!loading ? CustomIconName.CHEVRON_DOWN : undefined}
                rightElm={loading ? <Spinner size={16} /> : undefined}
                leftElm={<Avatar size={20} user={idea.author} />}
            >
                <Flex gap="0 4px" className={styles.meta}>
                    {desc}
                </Flex>
            </Button>
        </Select>
    );
}

export default observer(IdeaAuthorSuggest);

import { computed, makeAutoObservable, observable, runInAction } from 'mobx';

import api from 'api';
import { EMPTY_ARRAY } from 'constants/atoms';
import { CRITERION_TYPE } from 'constants/Criterion';
import FIELD_TYPE from 'constants/FieldType';
import { isYT, PROVIDER_DUCALIS } from 'constants/Providers';
import { getSprintStatusIntent } from 'utils';

import {
    POSITIONS_DELEGATE_IT,
    POSITIONS_DO_IT,
    POSITIONS_DONT_DO_IT,
    POSITIONS_SCHEDULE_IT,
} from 'pages/Board/Matrix/constants';

import alertStore from 'store/models/Alert';
import * as IdeaApi from 'store/models/api/Idea';
import * as IssueApi from 'store/models/api/Issue';
import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import { Idea } from 'store/models/Idea';
import IssuesData from 'store/models/IssuesData';
import { clearDescription } from 'store/models/utils/clearDescription';

import {
    CURRENT_ORG_ID,
    CURRENT_USER_ID,
    DICTIONARY_LABELS,
    DICTIONARY_STATUSES,
    DICTIONARY_TYPES,
    FILTER_TYPES,
    IS_PUBLIC_BOARD,
    IS_SHARING_BOARD,
    UNSAVED_MODEL_ID,
    USER_ROLE,
} from 'utils/consts';
import copyToClipboard from 'utils/copyToClipboard';
import failRequest from 'utils/failRequest';
import { hasOwnProperty } from 'utils/hasOwnProperty';
import logEvent from 'utils/logEvent';
import { sendToSentry } from 'utils/sentry';

import { GhostNotification } from 'components/AppToaster';

import { issuesList } from './IssuesList';
import { mainStore } from './MainStore';
import { fillWithInterval } from './utils/fillWithInterval';

export class Issue {
    /** {string|number} */
    boardId = 0;

    _links = null;
    assignee_id = null;
    attachments = [];
    create_date = null;
    description = '';
    due_date = null;
    failCells = [];
    files = null;
    id = null;
    internal_id = null;
    key = null;
    isRemoved = false;
    labels = null;
    ideasData = [];
    issueLinks = null;
    sprints_count = null;
    members = null;
    name = '';
    parent_task = null;
    projects = null;
    projects_sections = null;
    reporter_id = null;
    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // blockRowNumber = null;
    sprint = null;
    tempid = null;
    update_date = null;
    checklists = null;
    subtasks = EMPTY_ARRAY;
    loading = false;
    status_id = null;
    type_id = null;
    label_ids = [];

    platform_id = null;
    provider = '';
    is_demo = false;
    needFocus = false;
    customFields = {};
    isPreview = false;
    subtask = false;

    aiText = '';
    aiTitle = '';

    /**
     * Mark for issues from another board
     * Don`t show menu and watchers
     *
     * @type {boolean}
     */
    isExternal = false;

    constructor(data) {
        this.isPreview = data?.isPreview ?? false;
        this.fillModel(data);

        // TODO: https://concertwithme.atlassian.net/browse/DCLS-5892
        // заменить на observable.ref и замерить рендеры и расчеты computed полей
        // протестировать equals: ... для вычисляемых полей
        makeAutoObservable(this, {
            externalData: computed({ keepAlive: true }),
            totalValue: computed({ keepAlive: true }),
            idea: computed({ keepAlive: true }),
            ideas: computed({ keepAlive: true }),
            row_number: computed({ keepAlive: true }),

            customFields: observable.struct,
            issueLinks: observable.struct,
            attachments: observable.struct,
            _links: observable.struct,
            labels: observable.struct,
            members: observable.struct,
            checklists: observable.struct,
            subtasks: observable.struct,
            ideasData: observable.struct,
            sprint: observable.struct,
            label_ids: observable.struct,
            parent_task: observable.struct,

            status: computed.struct,
            isTop: computed.struct,
            isEditable: computed.struct,
            reporter: computed.struct,
            assignee: computed.struct,
        });
    }

    fillModel(data) {
        this.isRemoved = false;
        this._links = data._links;
        this.assignee_id = data.assignee_id;
        this.attachments = data.attachments;
        this.create_date = data.create_date;
        this.due_date = data.due_date;
        this.files = null;
        this.id = data.id;
        this.internal_id = data.internal_id || null;
        this.key = data.key;
        this.labels = data.labels || null;
        this.sprints_count = data.sprints_count || null;
        this.members = data.members || null;
        this.name = data.name;
        this.parent_task = data.parent_task;
        this.projects = data.projects || null;
        this.projects_sections = data.projects_sections || null;
        this.reporter_id = data.reporter_id;
        this.sprint = data.sprint || null;
        this.issueLinks = data.issueLinks || null;

        this.update_date = data.update_date;
        this.checklists = data.checklists || null;
        this.subtasks = data.subtasks || EMPTY_ARRAY;
        this.loading = data.loading || false;
        this.platform_id = data.platform_id;
        this.provider = data.provider;
        this.is_demo = data.is_demo || false;
        this.ideasData = data.ideasData || [];

        this.boardId = typeof data.boardId !== 'undefined' ? data.boardId : this.boardId;

        // custom
        this.tempid = data.tempid || this.tempid;
        this.needFocus = data.needFocus || this.needFocus;

        // Our boards
        this.status_id = data.status_id;
        this.type_id = data.type_id;
        this.label_ids = data.label_ids;

        this.customFields = data.customFields || {};
        this.subtask = data.subtask || false;

        this.description = clearDescription(data.description, data.provider);

        this.isExternal = data.isExternal || false;
    }

    get isUserNotAllVoted() {
        return this.externalData?.isUserNotAllVoted;
    }

    getKey() {
        return this.tempid || this.id;
    }

    get value() {
        return this.getTotalValueEffort(false);
    }

    get effort() {
        return this.getTotalValueEffort(true);
    }

    isQuadrant() {
        const value = this.value;
        const effort = this.effort;
        switch (true) {
            case value >= this.board.midValue && effort >= this.board.midEffort:
                return POSITIONS_SCHEDULE_IT;
            case value < this.board.midValue && effort > this.board.midEffort:
                return POSITIONS_DONT_DO_IT;
            case value <= this.board.midValue && effort <= this.board.midEffort:
                return POSITIONS_DELEGATE_IT;
            case value > this.board.midValue && effort < this.board.midEffort:
                return POSITIONS_DO_IT;
            default:
                return null;
        }
    }

    get canPushToTracker() {
        return !(
            !mainStore.organization.canPushToTracker ||
            mainStore.currentUser?.isViewer ||
            this.id === UNSAVED_MODEL_ID ||
            this.provider !== PROVIDER_DUCALIS ||
            !this.board?.id ||
            !this.board?.canSendToPlatform ||
            this.board.needNewOwner
        );
    }

    get apiBaseEndpoint() {
        return `/boards/${this.realIdBoard}/issues`;
    }

    get apiCurrentEndpoint() {
        return `${this.apiBaseEndpoint}/${this.id}`;
    }

    get board_name() {
        return this.board?.fullName || '';
    }

    get point() {
        const label = this.key ? `${this.key}: ${this.name}` : this.name;
        return {
            y: this.value,
            x: this.effort,
            label: label.length > 50 ? label.substring(0, 50) + '...' : label,
            alignment: this.alignment,
            totalValue: this.totalValue,
            id: this.id,
        };
    }

    get watchers() {
        return mainStore.watchersIds.get(this.id) ?? [];
    }

    get comments() {
        return mainStore.questions.filter((el) => el.issue_id === this.id);
    }

    get closedComments() {
        return this.comments?.filter((comment) => !comment.open);
    }

    get activeComments() {
        return this.comments?.filter((comment) => comment.open);
    }

    /**
     * Open Questions
     * Used inside filter + column view
     *
     * @returns {number}
     */
    get openRequestsCount() {
        return this.activeComments.length;
    }

    get innerStatus() {
        if (!this.status_id) {
            return null;
        }
        return dictionaryStore.issueStatuses?.find((el) => el.id === this.status_id);
    }

    get innerType() {
        if (!this.type_id) {
            return null;
        }
        return dictionaryStore.issueTypes.find((el) => el.id === this.type_id);
    }

    get innerLabels() {
        if (!this.label_ids) {
            return EMPTY_ARRAY;
        }
        const list = dictionaryStore.issueLabels || [];
        return this.label_ids.map((id) => list.find((el) => el.id === id)).filter((el) => el);
    }

    get all_voted() {
        return this.externalData?.all_voted;
    }

    get users_alignment() {
        return this.externalData?.users_alignment;
    }

    get votes() {
        return this.externalData?.votes || EMPTY_ARRAY;
    }

    get skipped() {
        return this.externalData?.skipped;
    }

    get unvoted() {
        return this.externalData?.unvoted;
    }

    get voting_percent() {
        return this.externalData?.voting_percent;
    }

    get userVotes() {
        return this.externalData?.userVotes;
    }

    get reporter() {
        if (!this.reporter_id) {
            return this.customFields.reporter;
        }
        return this.findUserById(this.reporter_id) || null;
    }

    get assignee() {
        if (!this.assignee_id) {
            return this.customFields.assignee;
        }
        return this.findUserById(this.assignee_id) || null;
    }

    get membersList() {
        return this.customFields.members || null;
    }

    get isDone() {
        return (this.innerStatus && this.innerStatus.system_status === 'done') || false;
    }

    get statusObj() {
        if (this.status_id) {
            return this.innerStatus;
        }

        return this.customFields?.status || (isYT(this.provider) && this.customFields?.State) || null;
    }

    get typeObj() {
        return this.type_id ? this.innerType : this.customFields?.issuetype || null;
    }

    get labelsList() {
        if (this.label_ids) {
            return this.innerLabels || [];
        }
        return this.labels || [];
    }

    get board() {
        return mainStore.boardsList.boards.find((board) => [board.id, board.public_id].includes(this.boardId));
    }

    get sprintColor() {
        return this.sprint ? getSprintStatusIntent(this.sprint.state) : 'none';
    }

    get row_number() {
        if (this.board.hideScores) return -Infinity;
        const index = this.board.orderedIssuesIds.indexOf(this.id);
        return index >= 0 ? index + 1 : null;
    }

    get isTop() {
        const board = this.board;
        if (!board || this.board.hideScores) return false;

        return this.row_number !== null && typeof this.externalData?.total === 'number'
            ? this.row_number <= board.top_priority
            : false;
    }

    get hasMyQuestion() {
        return this.comments?.some(
            (el) => el.open && el.users.some((item) => !item.resolved && item.user_id === CURRENT_USER_ID),
        );
    }

    get hasQuestionForMe() {
        return this.comments?.find((el) => el.open && el.assignee_id === CURRENT_USER_ID) || false;
    }

    get hasOpenRequests() {
        return this.comments?.some((el) => el.open) || false;
    }

    get requestsUsersIds() {
        return this.activeComments.map((comment) => comment.users.map((el) => el.user_id)).flat();
    }

    get hasAnotherQuestions() {
        return this.hasOpenRequests && !this.hasMyQuestion && !this.hasQuestionForMe;
    }

    get hasTotal() {
        return typeof this.externalData?.total === 'number';
    }

    get reporterUser() {
        const reporter = this.reporter;
        if (reporter) {
            return (
                mainStore.users.activeUsersWithoutMe.find(
                    (el) => el.email === reporter.email || el.name === reporter.name,
                ) || null
            );
        }
        return null;
    }

    get topLink() {
        return `${this.board.basePath}/issue-${this.id}`;
    }

    get href() {
        return `${window.location.origin}${this.topLink}`;
    }

    get totalValue() {
        if (this.board.hideScores) {
            return null;
        }
        return this.externalData?.total;
    }

    get platform() {
        const platform_id = this.platform_id;
        if (mainStore.platformsList.platforms) {
            return mainStore.platformsList.platformsIds.get(platform_id);
        }
        return undefined;
    }

    get isEditable() {
        return (
            !IS_PUBLIC_BOARD &&
            !this.isPreview &&
            this.provider === PROVIDER_DUCALIS &&
            !mainStore.currentUser?.isViewer
        );
    }

    get idea() {
        const issue_id = this.id;
        return (this.board && this.board.allIdeas.find((idea) => idea.issue_id === issue_id)) || undefined;
    }

    get ideas() {
        const ids = this.ideasIds;
        return ids.map((id) => issuesList.ideasIds.get(id)).filter((el) => el);
    }

    get ideasIds() {
        return this.ideasData.map((el) => el.id);
    }

    getUnevaluatedUsers() {
        if (!this.externalData) return [];
        const ids = this.votes.filter((el) => !el.unvoted).map((el) => el.user_id);
        return this.board.activeUsers.filter(
            (user) =>
                user.role !== USER_ROLE.Viewer &&
                !ids.includes(user.id) &&
                this.board.getCriteriaByUserId(user.id)?.length > 0,
        );
    }

    getTotalValueEffort(isEffort) {
        if (!this.externalData) return 0;

        if (this.externalData.total === null || !this.externalData.cr_weightless.size) return 0;

        const crIds = Array.from(this.externalData.cr_weightless.keys());

        return (
            crIds.reduce((res, criterion_id) => {
                if (!this.board || this.board.matrixNonActiveCriteria.has(criterion_id)) {
                    return res;
                }

                const boardCriteria = criteriaStore.criteriaBoardsIds.get(this.board.getBoardCriterionId(criterion_id));
                const criteria = criteriaStore.criteriaIds.get(criterion_id);

                if (
                    !criteria ||
                    !boardCriteria ||
                    ![CRITERION_TYPE.effort, CRITERION_TYPE.value].includes(criteria.type) ||
                    !this.externalData
                ) {
                    return res;
                }

                if (
                    (isEffort && criteria.type === CRITERION_TYPE.effort) ||
                    (!isEffort && criteria.type === CRITERION_TYPE.value)
                ) {
                    const value =
                        this.externalData.custom_votes.get(criterion_id)?.value ??
                        this.externalData.cr_weightless.get(criterion_id);

                    res += (value || 0) * boardCriteria.coefficient;
                }

                return Math.ceil(res);
            }, 0) || 0
        );
    }

    get skippedUsersIds() {
        if (!this.externalData) return [];
        return this.votes.filter((el) => el.skipped).map((el) => el.user_id);
    }

    get status() {
        return this.customFields?.status || this.statusObj?.name;
    }

    get issuetype() {
        return this.customFields?.issuetype || this.typeObj?.name;
    }

    get type() {
        return this.issuetype;
    }

    async createIdea(description = '', title = null) {
        const idea = new Idea({
            name: title || this.name,
            description: description,
            issue_id: this.id,
            boardId: this.board.id,
            board_id: this.board.id,
            tempId: Date.now(),
        });

        await idea.save(idea);
    }

    /**
     * Used for columns
     * old name - votingIssueCount
     * Don`t rename
     *
     * @returns {number|null}
     */
    get idea_votes_count() {
        if (!this.ideas.length) return null;
        return this.ideas.reduce((res, idea) => res + idea.votesCount, 0);
    }

    /**
     * Used for columns
     * Don`t rename
     *
     * @returns {number|null}
     */
    get idea_voters_count() {
        if (!this.ideas.length) return null;
        return this.ideas.reduce((res, idea) => res + idea.votersCount, 0);
    }

    get isPublic() {
        return this.idea?.id > 0 && this.idea.allow_voting;
    }

    getCustomCriterionValue(criterion) {
        return this?.[criterion.custom_field_name] ?? this.customFields?.[criterion.custom_field_name];
    }

    get alignment() {
        return this.externalData?.alignment;
    }

    get alignmentValue() {
        return this.externalData?.alignment;
    }

    get uuid() {
        return `${this.id}-${this.boardId}`;
    }

    get externalData() {
        const key = this.uuid;
        return issuesList.issuesData.get(key) ?? new IssuesData({ id: this.id, boardId: this.boardId });
    }

    getVotingFieldValue(votingField, type) {
        const valuesArray = this.ideas.map((idea) => idea[votingField]);

        if (type === FILTER_TYPES.INT) return valuesArray.reduce((res, value) => res + value, 0);
        if ([FILTER_TYPES.STRING].includes(type)) return valuesArray.join(', ');

        return this.idea?.[votingField];
    }

    getAlignmentFieldValue(alignment) {
        if (this.board.hideScores) return null;
        return this.externalData?.criteriaAlignmentIds?.get(alignment);
    }

    getCriterionFieldValue(field) {
        if (this.board.hideScores) return null;

        const criterion = criteriaStore.criteriaIds.get(+field);
        if (!criterion) return null;

        if (criterion.is_custom) {
            return this.getCustomCriterionValue(criterion);
        }

        if (!this.externalData) return null;
        return this.externalData.custom_votes.get(+field)?.value ?? this.externalData.cr_weightless.get(+field);
    }

    getReportRowNumber() {
        if (this.board.hideScores) return -Infinity;

        // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
        // if (mainStore.report?.considerBlockers)
        //     return mainStore.report.orderedIssuesIdsByBlocks.get(this.id);
        if (mainStore.report?.orderedIssuesIds) {
            const orderedIndex = mainStore.report.orderedIssuesIds.indexOf(this.uuid);
            return orderedIndex >= 0 ? orderedIndex + 1 : undefined;
        }
        if (this.totalValue === undefined) return this.row_number || undefined;
        return -1 * this.totalValue;
    }

    /**
     * Get issue value by field
     *
     * @param {string|number|null} field
     * @param {string} type
     * @param {FieldType} fieldType
     * @returns {string|number|null|undefined}
     */
    getValueByField(field, type, fieldType) {
        if (field === null) return null;

        if (type === 'input') {
            return this.userVotes?.get(+field);
        }

        if (fieldType === FIELD_TYPE.voting) return this.getVotingFieldValue(field, type);
        if (fieldType === FIELD_TYPE.alignment) return this.getAlignmentFieldValue(field);
        if (fieldType === FIELD_TYPE.criterion) return this.getCriterionFieldValue(+field);

        if (field === 'report_row_number') {
            return this.getReportRowNumber();
        }

        if (['labels_array', 'tags', 'labels', 'label_ids'].includes(field)) {
            return this.labelsNames;
        }

        if (field === 'status_id') {
            return this.status;
        }

        if (field === 'type_id') {
            return this.type;
        }

        if (type === 'user-alignment') {
            return this.externalData?.usersAlignmentIds?.get(+field);
        }

        if (hasOwnProperty(this.customFields, field)) {
            return this.customFields[field];
        }

        return this[field];
    }

    getUserCriteriaScore(userId) {
        if (!this.externalData || !this.externalData.votesUsersId.has(userId)) {
            return EMPTY_ARRAY;
        }
        const votes = this.externalData.votesUsersId.get(userId);

        if (!votes) return EMPTY_ARRAY;

        return votes.slice().sort((a, b) => {
            const valueA = criteriaStore.criteriaBoardsIds.get(
                `${this.board.id}_${a.criterion_id}_${CURRENT_ORG_ID}`,
            )?.position;
            const valueB = criteriaStore.criteriaBoardsIds.get(
                `${this.board.id}_${b.criterion_id}_${CURRENT_ORG_ID}`,
            )?.position;
            return valueA - valueB;
        });
    }

    get boardCriteriaScores() {
        if (!this.externalData.cr_weightless?.size) return EMPTY_ARRAY;

        return Array.from(this.externalData.cr_weightless.keys()).sort((a, b) => {
            const valueA = criteriaStore.criteriaBoardsIds.get(`${this.board.id}_${a.id}_${CURRENT_ORG_ID}`)?.position;
            const valueB = criteriaStore.criteriaBoardsIds.get(`${this.board.id}_${b.id}_${CURRENT_ORG_ID}`)?.position;
            return valueA - valueB;
        });
    }

    // eslint-disable-next-line
    updateModel({ status, type, ...data }) {
        Object.assign(this, data);
    }

    update = (data) => {
        this.updateModel(data);
        return this.pushIssueToServer();
    };

    findUserById = (userId) => {
        return mainStore.users.users?.find((el) => el.id === userId) || null;
    };

    getDictByName(dictionary) {
        return this.board?.getDictByName(dictionary) ?? [];
    }

    getTotalScoreForSort = () => {
        if (!this.board.hideScores && typeof this.externalData?.total === 'number') {
            return this.externalData.total;
        }

        return -Infinity;
    };

    fillWithInterval = fillWithInterval;

    getFieldValueByDictionary(dictionary) {
        if (this.provider !== PROVIDER_DUCALIS) {
            switch (dictionary) {
                case DICTIONARY_TYPES:
                    return this.type;
                case DICTIONARY_STATUSES:
                    return this.status;
                case DICTIONARY_LABELS:
                    return this.labelsList;
                default:
                    return null;
            }
        }

        switch (dictionary) {
            case DICTIONARY_TYPES:
                return this.innerType;
            case DICTIONARY_STATUSES:
                return this.innerStatus;
            case DICTIONARY_LABELS:
                return this.innerLabels;
            default:
                return null;
        }
    }

    async setLabel(label) {
        runInAction(() => {
            if (this.label_ids) {
                this.label_ids = [...this.label_ids, label.id];
            } else {
                this.label_ids = [label.id];
            }
        });
        if (this.id === UNSAVED_MODEL_ID) {
            await this.pushIssueToServer();
        } else {
            try {
                await api.post(`${this.apiCurrentEndpoint}/labels/${label.id}`);
            } catch (error) {
                failRequest(error);
            }
        }
    }

    async removeLabel(label) {
        runInAction(() => {
            this.label_ids = this.label_ids.filter((id) => id !== label.id);
        });
        try {
            await api.delete(`${this.apiCurrentEndpoint}/labels/${label.id}`);
        } catch (error) {
            failRequest(error);
        }
    }

    getDictionaryField(dictionary) {
        if (dictionary === DICTIONARY_TYPES) return 'type_id';
        if (dictionary === DICTIONARY_STATUSES) return 'status_id';
        return null;
    }

    async setProps({ dictionary, value }) {
        if (dictionary === DICTIONARY_LABELS) {
            return this.setLabel(value);
        }

        const field = this.getDictionaryField(dictionary);

        if (!field) {
            sendToSentry('Empty setProps params', { dictionary, value });
            return;
        }

        await this.update({ [field]: value.id });
    }

    get blockedByIssue() {
        return mainStore.blockedByIssues.has(this.id);
    }

    get blocksIssues() {
        return mainStore.blocksIssues.has(this.id);
    }

    get fullVote() {
        if (!this.externalData) return false;

        const criteria = this.board.currentUserCriteria;
        return criteria.length === this.userVotes.size || this.skipped;
    }

    failVote({ criterionId, vote }) {
        this.failCells.push({ criterion_id: criterionId, vote });
    }

    changeVote({ value, criterion }) {
        this.externalData.changeVote({ value, criterion });
    }

    // Make removed animation
    setRemoved(isRemoved) {
        this.isRemoved = isRemoved;
    }

    removeHandle(fromServer = false, callBack) {
        this.setRemoved(true);
        if (fromServer) {
            this.remove().then(() => callBack?.());
        }
    }

    copyLink = () => {
        if (this.id === UNSAVED_MODEL_ID) {
            return;
        }
        logEvent('Copy issue link');
        if (IS_SHARING_BOARD) {
            copyToClipboard(this.href);
        } else {
            copyToClipboard(this._links.internalUrl);
        }

        GhostNotification.show({ message: 'Copied to clipboard', timeout: 1000 });
    };

    deleteFromBoard = (callBack) => {
        alertStore.confirm({
            message: 'Do you really want to permanently delete this issue from the board?',
            onConfirm: () => {
                logEvent('Remove issue');
                this.removeHandle(true, callBack);
            },
            confirmButtonText: 'Delete',
        });
    };

    hasText(query) {
        if (!this.name) {
            return false;
        }
        const name = this.fullName;
        const normalizedName = name.toLowerCase();
        const normalizedQuery = String(query).toLowerCase();
        return normalizedName.indexOf(normalizedQuery) >= 0;
    }

    getVotesForAlignmentTooltip(crId) {
        const users = this.board.activeUsersByMe;

        const sortedVotes = this.votes.slice().sort((a, b) => {
            const indexA = users.findIndex((user) => user.id === a.user_id);
            const indexB = users.findIndex((user) => user.id === b.user_id);
            return indexA - indexB;
        });

        return sortedVotes
            .map((vote) => {
                const user = this.board.activeUsers.find((u) => u.id === vote.user_id);
                if (!user) {
                    return null;
                }
                let value;
                if (vote.skipped) {
                    return null;
                } else if (this.hasOpenRequests && this.requestsUsersIds.includes(user.id)) {
                    value = 'Ask question';
                } else if (this.externalData && this.externalData.votesUsersId.has(vote.user_id)) {
                    const userValue = this.externalData.votesUsersId
                        .get(vote.user_id)
                        ?.find((el) => el.criterion_id === crId)?.value;
                    if (userValue === undefined) {
                        return null;
                    }
                    value = userValue;
                }
                return {
                    name: user.name || user.email,
                    id: crId,
                    value: value,
                };
            })
            .filter((el) => el);
    }

    get fullName() {
        return this.key ? `${this.key}: ${this.name}` : String(this.name);
    }

    get statusName() {
        return this.innerStatus?.name;
    }

    get typeName() {
        return this.innerType?.name;
    }

    /**
     * Array of  Labels names
     * Used for filter, don`t rename
     *
     * @returns {[string]}
     */
    get labelsNames() {
        return this.customFields?.labels_array || this.customFields?.labels || this.labelsList.map((el) => el.name);
    }

    get realIdBoard() {
        return this.board?.id;
    }

    clearAIText = () => {
        this.aiText = '';
        this.aiTitle = '';
    };

    setAIText = (text) => {
        if (this.aiText === '') {
            this.aiText = text.replace(/^>/, '');
        } else {
            this.aiText = this.aiText + text;
        }
    };

    // API methods

    resolveRequest = IssueApi.resolveRequest;

    updateRequest = IssueApi.updateRequest;

    removeRequest = IssueApi.removeRequest;

    sendSubscribe = IssueApi.sendSubscribe;

    commentQuestion = IssueApi.commentQuestion;

    updateQuestionComment = IssueApi.updateQuestionComment;

    sendQuestion = IssueApi.sendQuestion;

    vote = IssueApi.vote;

    pushIssueToServer = IssueApi.pushIssueToServer;

    remove = IssueApi.remove;

    skipEvaluation = IssueApi.skipEvaluation;

    resumeEvaluation = IssueApi.resumeEvaluation;

    analyticsRead = IssueApi.analyticsRead;

    dropVotes = IssueApi.dropVotes;

    linking = IssueApi.linking;

    move = IssueApi.move;

    generateAI = IssueApi.generateAI;

    getTitleByAI = IssueApi.getTitleByAI;

    copyMoveToBoard = IdeaApi.copyMoveToBoard;

    changeWatcher = IssueApi.changeWatcher;
}

import { Overlay } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import IssueCard from 'modules/IssueCard';
import IdeaCard from 'modules/IssueCard/IdeaCard';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import { IS_VOTING_BOARD, ISSUE_CARD_POSITION } from 'utils/consts';
import debounce from 'utils/debounce';

import DraggableDiv from 'components/DraggableDiv/DraggableDiv';
import IssueCardWrapper from 'components/IssueCardWrapper';

import Divider from './Divider';

import styles from './IssueModal.module.sass';

const IssueModalDraggable = observer(({ position, size }) => {
    if (utilsStore.cardIsHidden) return null;

    const resizeIssue = debounce((deltaX) => {
        const multiplicator = position === ISSUE_CARD_POSITION.LEFT ? 1 : -1;
        const widthMax = Math.max(size + multiplicator * deltaX, !IS_VOTING_BOARD ? 500 : 320);
        const width = Math.min(widthMax, 800);
        utilsStore.updateSettings({ field: IS_VOTING_BOARD ? 'issueVotingWidth' : 'issueWidth', value: width });
    }, 10);

    return (
        <Divider position={position === ISSUE_CARD_POSITION.LEFT ? 'right' : 'left'}>
            <DraggableDiv onDrag={resizeIssue} />
        </Divider>
    );
});

const IssueModalContent = observer(function IssueModal({ height, position, isReleaseNote, showAlignment, className }) {
    const size = IS_VOTING_BOARD ? utilsStore.settings.issueVotingWidth : utilsStore.settings.issueWidth;
    const hidden = utilsStore.cardIsHidden;
    const classesCard = classNames(styles.modal, className, {
        [styles.left]: !hidden && position === ISSUE_CARD_POSITION.LEFT,
        [styles.right]: !hidden && position === ISSUE_CARD_POSITION.RIGHT,
        [styles.hidden]: hidden,
        [styles.voting]: IS_VOTING_BOARD,
        [styles.releaseNote]: isReleaseNote,
    });

    function onToggleHide() {
        utilsStore.toggleCardHidden();
    }

    return (
        <IssueCardWrapper
            style={{ width: size, height: height + 5 }}
            slim={utilsStore.isSlim}
            className={classesCard}
            onClick={hidden ? onToggleHide : undefined}
            itemType="https://schema.org/DiscussionForumPosting"
            itemScope
            aria-hidden={hidden}
        >
            {IS_VOTING_BOARD ? <IdeaCard /> : <IssueCard showAlignment={showAlignment} onHide={onToggleHide} />}

            <IssueModalDraggable position={position} size={size} />
        </IssueCardWrapper>
    );
});

export const IssueModal = observer(function IssueModal({ position, onClose, height, showAlignment, isReleaseNote }) {
    return (
        <Overlay
            backdropClassName={styles.backdrop}
            autoFocus={false}
            shouldReturnFocusOnClose={false}
            enforceFocus={false}
            hasBackdrop={isReleaseNote ? !utilsStore.cardIsHidden : false}
            portalClassName={styles.portal}
            isOpen={!!issuesList.activeIssue?.id && !issuesList.activeIssue?.isRemoved}
            canOutsideClickClose={isReleaseNote ? !utilsStore.cardIsHidden : false}
            onClose={onClose}
        >
            <IssueModalContent
                position={position}
                showAlignment={showAlignment}
                height={height}
                isReleaseNote={isReleaseNote}
            />
        </Overlay>
    );
});

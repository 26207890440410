import React, { useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { DOWN, UP, VIEWS } from 'utils/consts';
import delay from 'utils/delay';
import { findIndexFrom, findLastIndexFrom } from 'utils/findIndexFrom';
import getKeyCode from 'utils/getKeyCode';
import logEvent from 'utils/logEvent';
import { trackCrispEvent } from 'utils/trackCrispEvent';

import EmptyLoader from 'components/EmptyLoader';
import PageTitle from 'components/PageTitle';

import FocusModeForm from './FocusModeForm';

function CompleteView({ board }) {
    let navigate = useNavigate();
    useEffect(() => {
        delay(300).then(() => navigate(`/board/${board.id}/summary`));
    }, [board.id, navigate]);

    return null;
}

function findClearIssue(item) {
    return item.unvoted;
}

function findIssueIndex(list, index = 0, findLast = false) {
    if (findLast) {
        return findLastIndexFrom(list, findClearIssue, index);
    }
    return findIndexFrom(list, findClearIssue, index);
}

export function getNextIndex(index, list) {
    let issIndex = findIssueIndex(list, index + 1);
    if (issIndex !== -1) {
        return issIndex;
    }
    return findIssueIndex(list) || 0;
}

export function getPrevIndex(index, list) {
    let issIndex = findIssueIndex(list, index - 1, true);
    if (issIndex !== -1) {
        return issIndex;
    }
    return (index && findIssueIndex(list, -1, true)) || list.length - 1;
}

function getAnotherIssue(issue, row, isNext = false) {
    const list = mainStore.activeBoard.allAssessmentIssues;
    const index = isNext ? getNextIndex(row, list) : getPrevIndex(row, list);
    const another = mainStore.activeBoard.allAssessmentIssues[index];

    if ((another && !issue) || (another && another.id !== issue.id)) {
        return mainStore.activeBoard.allAssessmentIssues[index];
    }
    return null;
}

function FocusMode() {
    const { id } = useParams();
    const [row, setRow] = useState(0);
    const [allCount, setAllCount] = useState(null);

    useEffect(() => {
        function bindEvents(event) {
            const keyCode = getKeyCode(event);
            const len = mainStore.activeBoard?.assessmentIssuesCount || 0;
            if (len === 0) {
                return;
            }
            if (!event.altKey && keyCode === DOWN) {
                onDown();
            } else if (!event.altKey && keyCode === UP) {
                onUp();
            }
        }

        mainStore.boardsList.setActiveBoardId(+id);

        logEvent('Focus mode: Open');
        trackCrispEvent('Used Focus Mode');
        document.addEventListener('keyup', bindEvents, false);

        return () => {
            logEvent('Focus mode: Close');
            document.removeEventListener('keyup', bindEvents, false);
        };
    }, [id]);

    const board = mainStore.activeBoard;
    const issuesCount = board?.assessmentIssuesCount;

    useEffect(() => {
        if ((allCount === null && issuesCount) || (allCount !== null && allCount < issuesCount)) {
            setAllCount(issuesCount);
        }
    }, [allCount, issuesCount]);

    const issue = useMemo(() => {
        if (issuesCount === 0) {
            return null;
        }
        if (row >= issuesCount) {
            return mainStore.activeBoard.allAssessmentIssues[0];
        }
        return mainStore.activeBoard.allAssessmentIssues[row] || null;
    }, [row, issuesCount]);

    useEffect(() => {
        issue && issuesList.activeIssue?.id !== issue.id && issuesList.setActiveIssue(issue);
    }, [issue]);

    if (!mainStore.activeBoard) {
        return null;
    }

    function onDown() {
        setRow((state) => {
            const row = getNextIndex(state, mainStore.activeBoard.allAssessmentIssues);
            logEvent('Focus mode: key Down', { row, state });
            return row;
        });
    }

    function onUp() {
        setRow((state) => {
            const row = getPrevIndex(state, mainStore.activeBoard.allAssessmentIssues);
            logEvent('Focus mode: key Up', { row, state });
            return row;
        });
    }

    function onComplete() {
        setRow((state) => {
            const row = getNextIndex(state, mainStore.activeBoard.allAssessmentIssues);
            logEvent('Focus mode: Get next issue', { row, state });
            return row;
        });
    }

    const criteria = mainStore.activeBoard.currentUserCriteria;

    if (!criteria || mainStore.activeBoard.allAssessmentIssues === null) {
        return EmptyLoader();
    }

    let completeView = null;

    if (!issue && !issuesCount) {
        completeView = <CompleteView board={mainStore.activeBoard} />;
    }

    const next = getAnotherIssue(issue, row, true);
    const prev = getAnotherIssue(issue, row);

    return (
        <>
            <PageTitle page={VIEWS.FOCUS_MODE} />
            <FocusModeForm
                listCount={mainStore.activeBoard.assessmentIssuesCount}
                board={mainStore.activeBoard}
                completeView={completeView}
                onUp={onUp}
                onDown={onDown}
                allCount={allCount}
                next={next}
                prev={prev}
                criteria={criteria}
                index={row}
                issue={issue}
                onComplete={onComplete}
            />
        </>
    );
}

export default observer(FocusMode);

import { makeAutoObservable, observable, reaction } from 'mobx';

import FAKE_LIST from 'constants/FakeList';
import { PROVIDER_LINEAR } from 'constants/Providers';
import { getRandom, queryInText } from 'utils';

import * as BoardApi from 'store/models/api/Board';
import columnsStore from 'store/models/Columns';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { PlatformSync } from 'store/models/PlatformSync';
import { View } from 'store/models/View';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';
import {
    CURRENT_USER_ID,
    IS_PUBLIC_BOARD,
    PAYWALL_LIMITS,
    SORT_DIRECTION,
    UNSAVED_MODEL_ID,
    VIEWS,
} from 'utils/consts';
import logEvent from 'utils/logEvent';

import { COLUMNS } from 'components/MasterTable/components/Columns';

import * as ReportApi from './api/Report';

const DEFAULT_REPORT_EMOJIS = [
    '🔥',
    '👑',
    '💼',
    '🎓',
    '🎏',
    '🎌',
    '🔮',
    '💾',
    '💻',
    '🔑',
    '💡',
    '🔍',
    '📊',
    '📈',
    '📉',
    '📌',
    '🚧',
];

const DEFAULT_REPORT = {
    id: -1,
    name: 'Report title',
    emoji: null,
    boards: [],
    show_only_top: true,
};

const REPORT_DEFAULT_SORT_SETTINGS = { id: 'report_row_number', direction: SORT_DIRECTION.asc, type: undefined };

export default class Report {
    id = null;
    name = null;
    emoji = null;
    boardIds = [];
    activeColumns = {};
    columnsIds = [];
    show_only_top = true;
    validation = null;
    loader = false;
    sync = null;
    consider_blockers = false;
    created = '';

    view = new View({ id: VIEWS.TOP });

    /**
     * @type {IssuesFilter[]}
     */
    searches = [];

    filter = null;

    platformSync = null;

    query = '';

    selectedSyncFields = null;
    defaultSyncFields = null;

    constructor(data = DEFAULT_REPORT) {
        makeAutoObservable(this, {
            platformSync: false,
            view: false,

            boardIds: observable.struct,
            activeColumns: observable.struct,
            columnsIds: observable.struct,
            sync: observable.struct,
            searches: observable.struct,
        });

        this.fill(data);

        this.platformSync = new PlatformSync(this.id, 'report');

        this.fillSortSettings();

        reaction(
            () => this.id,
            (id) => (this.platformSync.id = id),
            {
                onError: (error) => {
                    console.error('Reaction error:', error);
                },
            },
        );

        reaction(
            () => this.filter?.companyFilter,
            () => this.filter?.getIds(),
            {
                onError: (error) => {
                    console.error('Reaction error:', error);
                },
            },
        );

        if (!IS_PUBLIC_BOARD) {
            this.boards.forEach((board) => {
                if (!board.socket) return;
                board.socket.on('VotingIssue.vote', () => this.filter?.getIds());
                board.socket.on('VotingIssue.unvote', () => this.filter?.getIds());
            });
        }
    }

    updateModel({ active_columns, boards, ...object }) {
        Object.assign(this, object);

        boards && (this.boardIds = boards);

        if (active_columns?.fields && Array.isArray(active_columns.fields)) {
            columnsStore.fillColumns(active_columns.fields, true);
            this.columnsIds = active_columns.fields.map((el) => el.field_id);
        }
        if (active_columns?.positions) {
            this.activeColumns = active_columns.positions;
        }
    }

    get columns() {
        return this.columnsIds.map((columnId) => columnsStore.getColumnById(columnId));
    }

    fill({ id, emoji, boardIds, boards, ...data }) {
        this.emoji = emoji || DEFAULT_REPORT_EMOJIS[getRandom(DEFAULT_REPORT_EMOJIS.length) - 1];
        this.boardIds = boards || boardIds || [];
        this.id = id;

        this.updateModel(data);
    }

    set(data, validateCallback = undefined) {
        this.updateModel(data);
        validateCallback && this.validate(validateCallback);
    }

    /**
     * @return {Board[]}
     */
    get boards() {
        const boardIds = this.boardIds;
        if (!boardIds || boardIds.length === 0) {
            return [];
        }
        return mainStore.boardsList.boards.filter((board) => this.boardIds.includes(board.id));
    }

    get hasPrivateBoard() {
        return this.boards.some((board) => !board.hasAccess);
    }

    /**
     * List ids columns
     * @return {string[]}
     */
    getGridActiveColumnsList = () => {
        return Object.values(this.activeColumns[VIEWS.TOP] || {}).map((columnId) => String(columnId));
    };

    getAllColumnsByGrid() {
        return this.columns;
    }

    get tableColumns() {
        const columns = this.getColumnsByGrid();

        if (IS_PUBLIC_BOARD) {
            return columns.filter((el) => !el.votingField);
        }
        return columns;
    }

    getColumnsByGrid() {
        /**
         * List ids columns
         * @type {[String]}
         */
        const activeColumns = this.getGridActiveColumnsList().filter((columnId) => columnsStore.columns.has(columnId));

        activeColumns.unshift('report_row_number');

        return activeColumns.map((columnId) => {
            const column =
                COLUMNS.find((customColumn) => customColumn.id === columnId) || columnsStore.getColumnById(columnId);
            if (columnId === 'row_number') {
                return { ...column, name: undefined };
            }
            return column;
        });
    }

    fillSyncFields = (objFields) => {
        Object.assign(this, objFields);
    };

    get topPriority() {
        if (!mainStore.readyFetchAll) return FAKE_LIST;

        const orderedList = this.view.useSort(this.issues);

        const filteredList = this.useMainFilter(orderedList);

        if (
            !issuesList.activeIssue ||
            (issuesList.activeIssue.id !== UNSAVED_MODEL_ID &&
                !filteredList.map(({ id }) => id).includes(issuesList.activeIssue.id))
        ) {
            const row = issuesList.getInitialRow(filteredList.length);
            issuesList.setActiveIssue(filteredList[row]);
        }

        return filteredList;
    }

    get considerBlockers() {
        return this.consider_blockers;
    }

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // get orderedIssuesIdsByBlocks() {
    //     const orderedMap = new Map();
    //     sortIssuesByDependencies(this.issues, this.blockIssuesIds).forEach((el, index) => orderedMap.set(el.id, index));
    //     return orderedMap;
    // }

    get basePath() {
        return `/reports/${this.id}`;
    }

    get orderedIssuesIds() {
        return this.issues
            .slice()
            .sort((a, b) => {
                const totalA = a.totalValue ?? -Infinity;
                const totalB = b.totalValue ?? -Infinity;
                if (totalA === totalB) return 0;
                return totalA > totalB ? -1 : 1;
            })
            .map((el) => `${el.id}-${el.boardId}`);
    }

    get issues() {
        return issuesList.allIssues.filter(
            (el) => this.boardIds.includes(el.boardId) && !el.isDone && (this.show_only_top ? el.isTop : true),
        );
    }

    get ideas() {
        return this.boards.reduce((res, board) => {
            res.push(...board.allIdeas);
            return res;
        }, []);
    }

    get isNotFiltered() {
        return this.query.length <= 1 && !this.isFilter;
    }

    useMainFilter(list) {
        if (this.isNotFiltered) {
            return list;
        }
        let filterList;
        if (this.isFilter) {
            filterList = this.filter.useFilter(list);
        }

        if (this.query.length > 1) {
            filterList = (filterList || list).filter((issue) => queryInText(issue.fullName, this.query));
        }

        return filterList;
    }

    get countActiveIssues() {
        return this.boards
            .filter((board) => !board.isDucalis)
            .reduce((res, board) => {
                return res + board.countActiveIssues;
            }, 0);
    }

    get apiEndpoint() {
        return this.id !== -1 ? `/boards/reports/${this.id}` : '/boards/reports';
    }

    get fullName() {
        return `${this.emoji} ${this.name}`;
    }

    get syncStatus() {
        return this.sync?.settings?.connected || false;
    }

    get syncError() {
        return !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.TWO_WAY_SYNC_BOARDS) ? null : this.sync?.error;
    }

    get platform() {
        if (this.sync) {
            return mainStore.platformsList.platformsIds.get(this.sync.platform_id) || this.boards[0].platform;
        }
        return this.boards[0].platform;
    }

    get provider() {
        return this.sync?.provider;
    }

    get hasLinear() {
        return this.boards.some((board) => board.provider === PROVIDER_LINEAR);
    }

    get issuesProviderName() {
        return this.name;
    }

    get syncMapExists() {
        return !!this.sync.settings?.map_sync;
    }

    get currentUserIsOwner() {
        return CURRENT_USER_ID === this.sync?.owner_id;
    }

    get owner() {
        return this.sync?.owner_id ? mainStore.users.usersIds.get(this.sync.owner_id) : null;
    }

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // get blockIssuesIds() {
    //     const list = Array.from(mainStore.linkedIssues.values()).filter(
    //         (el) =>
    //             el.type === 'block' &&
    //             this.boardIds.includes(el.board_id) &&
    //             this.boardIds.includes(el.linked_board_id) &&
    //             !el.linked_issue_completed
    //     );
    //     const groups = groupBy(list, (link) => `${link.issue_id}-${link.board_id}`);
    //     const blocksIssues = new Map();
    //
    //     Object.entries(groups).forEach(([uid, list]) => {
    //         const clearList = list.map(({ linked_issue_id }) => linked_issue_id);
    //         blocksIssues.set(uid, clearList);
    //     });
    //     return blocksIssues;
    // }

    setQuery = (query) => {
        this.query = query;
    };

    setSort({ field, direction }) {
        const id = field.id || field.field;

        logEvent('Sort Column', { field, direction });
        if (
            this.view.sortField === id &&
            this.view.sortDirection === direction &&
            this.view.sortFieldType === field.type
        ) {
            this.view.setSort(REPORT_DEFAULT_SORT_SETTINGS);
        } else {
            this.view.setSort({ ...field, direction });
        }

        this.view.setSortIds(null);

        this.saveSortSettings();
    }

    fillSortSettings() {
        const settings = appStorage.get(`${APP_STORAGE_KEYS.report_sort}${this.id}`, {});
        const sortSettings = { ...REPORT_DEFAULT_SORT_SETTINGS, ...settings };
        this.view.setSort({ ...sortSettings, id: sortSettings.id || sortSettings.field });
    }

    saveSortSettings() {
        const state = { ...REPORT_DEFAULT_SORT_SETTINGS, ...this.view.getSortState() };
        appStorage.set(`${APP_STORAGE_KEYS.report_sort}${this.id}`, state);
    }

    /**
     * @param {IssuesFilter|null} filter
     */
    setFilter(filter) {
        this.filter = filter;
    }

    get hasFilter() {
        return this.filter !== null;
    }

    get isFilter() {
        return this.filter?.filter?.length > 0;
    }

    getFiltersByView() {
        return this.searches;
    }

    // API methods

    validate = ReportApi.validate;

    save = ReportApi.save;

    update = ReportApi.update;

    remove = ReportApi.remove;

    setSyncSettings = BoardApi.setSyncSettings;

    setSyncFields = BoardApi.setSyncFields;

    sortColumn = BoardApi.sortColumn;

    setColumn = BoardApi.setColumn;

    saveFilter = BoardApi.saveFilter;

    deleteFilter = BoardApi.deleteFilter;

    syncFieldUpdate = BoardApi.syncFieldUpdate;

    getSyncFields = BoardApi.getSyncFields;

    getAvailableSyncFields = BoardApi.getAvailableSyncFields;

    getColumns = BoardApi.getColumns;
}

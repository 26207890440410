import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { Idea } from 'store/models/Idea';

import { StTextEditor } from 'atoms/StTextEditor';

import ReadOnlyDescription from 'components/ReadOnlyDescription';

import PlatformDescription from './PlatformDescription';

function IssueDescription({ issue, editable, placeholder, autoFocus, className, defaultValue = '' }) {
    if (editable) {
        const isVoting = issue instanceof Idea;
        return (
            <StTextEditor
                allowYoutube
                hasMention={!isVoting}
                placeholder={placeholder}
                tabIndex={2}
                autoFocus={autoFocus}
                description={issue.description || defaultValue}
                onConfirm={(description) => {
                    description !== issue.description && issue.update({ description });
                }}
            />
        );
    }

    if (!issue.description) {
        return null;
    }

    if (issue.provider === PROVIDER_DUCALIS || issue instanceof Idea) {
        return <ReadOnlyDescription className={className} hasMention description={issue.description} />;
    }

    return (
        <PlatformDescription
            issue={issue}
            attachments={issue.attachments}
            description={issue.description}
            provider={issue.provider}
        />
    );
}

export default observer(IssueDescription);

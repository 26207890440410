import React, { lazy } from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { PLATFORM_CREATION_ID } from 'constants/Providers';

import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Signup from 'pages/Signup';

import appStorage, { APP_STORAGE_KEYS } from 'utils/AppStorage';
import { CURRENT_USER_ID, IS_VOTING_BOARD } from 'utils/consts';

import 'utils/round';

import AppWrapper from 'components/AppWrapper';
import PlanCheckoutCalculation from 'components/SubscriptionsPlans/PlanCheckoutCalculation';

import 'utils/polyfills';
import 'utils/globals';

import './sass/main.sass';

const App = lazy(() => import(/* webpackChunkName: 'App' */ './App'));
const VotingApp = lazy(() => import(/* webpackChunkName: 'VotingApp' */ 'apps/VotingApp'));

const root = document.getElementById('root');
const signupPage = document.getElementById('signup-page');
const loginForm = document.getElementById('login-form');

if (loginForm) {
    const loginPageRoot = createRoot(loginForm);
    loginPageRoot.render(
        <React.Suspense fallback={null}>
            <Login />
        </React.Suspense>,
    );
}

if (signupPage) {
    const signupPageRoot = createRoot(signupPage);
    signupPageRoot.render(
        <React.Suspense fallback={null}>
            <Signup />
        </React.Suspense>,
    );
}

if (root && IS_VOTING_BOARD) {
    const rootRoot = createRoot(root);
    rootRoot.render(
        <React.Suspense fallback={null}>
            <VotingApp />
        </React.Suspense>,
    );
} else if (root && !CURRENT_USER_ID && window.is404) {
    const rootRoot = createRoot(root);
    rootRoot.render(
        <AppWrapper contentOnly>
            <NotFound mainSite contentOnly />
        </AppWrapper>,
    );
} else if (root) {
    const rootRoot = createRoot(root);
    const platformCreationId = appStorage.get(APP_STORAGE_KEYS.force);
    appStorage.remove(APP_STORAGE_KEYS.force);
    rootRoot.render(
        <React.Suspense fallback={null}>
            <App platformCreationId={PLATFORM_CREATION_ID || platformCreationId} />
        </React.Suspense>,
    );
}

const billing = document.getElementById('calcForm');
if (billing) {
    const billingRoot = createRoot(billing);
    billingRoot.render(
        <Router>
            <PlanCheckoutCalculation quantity={window.paid_users} plan={window.selectedPlan} plans={window.plans} />
        </Router>,
    );
}

/**
 * For styles and colors insert (exp: auth page)
 */
const theme = document.getElementById('theme');
if (theme) {
    const themeRoot = createRoot(theme);
    themeRoot.render(<AppWrapper contentOnly />);
}

import React from 'react';

import { take } from 'utils/take';

import Avatar from 'components/Avatar';
import { StAvatarsList } from 'components/AvatarsList/StAvatarsList';
import AvatarTooltip from 'components/AvatarTooltip';
import Flex from 'components/Flex';
import RoundCounter from 'components/RoundCounter';

StAvatarsList.displayName = 'StAvatarsList';

export default function AvatarsList({
    isButton,
    className,
    onClick,
    list = [],
    limit = 1,
    size = 28,
    showZero = false,
    hideStatus,
    inline,
    inlineGap,
    hasTooltip,
}) {
    const len = list.length - limit;
    const limitList = take(list, len > 0 ? limit - 1 : limit);

    const css = !inline
        ? {
              '> * + *': {
                  marginLeft: size * -0.4,
                  zIndex: 1,
              },
          }
        : {};

    return (
        <StAvatarsList
            className={className}
            as={Flex}
            isButton={isButton}
            inline
            gap={inline && inlineGap}
            merge={!inline}
            css={css}
        >
            {limitList.map((user) =>
                !user ? null : hasTooltip ? (
                    <AvatarTooltip
                        hideStatus={hideStatus}
                        tooltipProps={{ position: 'top-right' }}
                        user={user}
                        size={size}
                        key={user.id || user.name}
                        onClick={onClick}
                    />
                ) : (
                    <Avatar
                        onClick={onClick}
                        user={user}
                        size={size}
                        key={user.id || user.name}
                        hideStatus={hideStatus}
                    />
                ),
            )}
            {showZero && list.length === 0 && <RoundCounter border size={size} count={0} />}
            {len > 0 && <RoundCounter border size={size} count={len + 1} />}
        </StAvatarsList>
    );
}

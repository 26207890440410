import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { EMPTY_ARRAY } from 'constants/atoms';
import { THEMES_MENU } from 'themes';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { StButton } from 'atoms/StButton';
import { StHr } from 'atoms/StHr';

import { AnchorButton, Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import SettingsLinkButton from 'components/SettingsLinkButton';

function googleLogout() {
    mainStore.db.dropDB();
    if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id &&
        typeof window.google.accounts.id.disableAutoSelect === 'function'
    ) {
        window.google.accounts.id.disableAutoSelect();
    }
}

export const UserMenuPopoverContent = observer(() => {
    const isAdmin = mainStore.currentUser?.isAdmin;

    const orgList = (mainStore.currentUser?.organizations || EMPTY_ARRAY).filter(
        (el) => el.id !== mainStore.organization.id,
    );

    return (
        <Flex column align="none" css={{ padding: 4 }}>
            {isAdmin && (
                <>
                    <SettingsLinkButton
                        link="/organization"
                        icon={CustomIconName.ORGANIZATION}
                        text={mainStore.organization.name}
                        minimal
                        block
                    />
                    <SettingsLinkButton
                        text="Integrations"
                        link="/integrations"
                        icon={CustomIconName.INTEGRATION}
                        minimal
                        block
                    />
                </>
            )}
            {!mainStore.currentUser?.isViewer && (
                <SettingsLinkButton
                    text="Account users"
                    block
                    minimal
                    icon={CustomIconName.VOTING}
                    link="/account-users"
                />
            )}
            {isAdmin && (
                <SettingsLinkButton
                    minimal
                    block
                    link="/billing-plan"
                    icon={CustomIconName.CARD}
                    text="Plan & Billing"
                />
            )}
            {orgList.length > 0 && (
                <>
                    <StHr css={{ margin: 8 }} />
                    <div style={{ margin: '0 8px 4px' }}>
                        <small className="o-4">Other Organizations</small>
                    </div>
                </>
            )}
            {orgList.map((el) => (
                <Button
                    minimal
                    block
                    icon={CustomIconName.ORGANIZATION}
                    key={el.id}
                    onClick={() => mainStore.organization.changeOrg(el)}
                    text={el.name}
                />
            ))}

            <StHr css={{ margin: 8 }} />

            <div style={{ margin: '0 8px 4px' }}>
                <small className="o-4">Theme</small>
            </div>

            {THEMES_MENU.map((theme) => (
                <Button
                    onClick={() => utilsStore.setTheme(theme.id)}
                    minimal
                    leftElm={
                        <StButton as="span" size={18} css={theme.css}>
                            Aa
                        </StButton>
                    }
                    text={theme.label}
                    key={theme.id}
                    rightIcon={theme.id === utilsStore.settings.theme ? CustomIconName.TICK : undefined}
                />
            ))}

            <StHr css={{ margin: 8 }} />
            <SettingsLinkButton
                text={<FormattedMessage id="page.profile" defaultMessage="Profile" />}
                icon={CustomIconName.PROFILE}
                link="/profile"
                minimal
                block
            />
            <SettingsLinkButton
                text="Notifications"
                icon={CustomIconName.NOTIFICATIONS}
                link="/notifications"
                minimal
                block
            />
            <AnchorButton
                block
                minimal
                onClick={googleLogout}
                icon={CustomIconName.LOGOUT}
                href="/login/logout"
                text="Logout"
            />
        </Flex>
    );
});

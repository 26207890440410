import React from 'react';

import { TooltipProvider } from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { HotkeysDialogProvider } from 'HotkeysDialogProvider';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router } from 'react-router-dom';

import { mainStore } from 'store/models/MainStore';
import screenStore from 'store/models/ScreenStore';
import { utilsStore } from 'store/models/UtilsStore';

import { IS_IFRAME, IS_IFRAME_TRANSPARENT, IS_IFRAME_WIDGET, IS_VOTING_BOARD } from 'utils/consts';

import './style.sass';

const AppWrapperDiv = observer((props) => {
    const classes = classNames('App', {
        'App--mobile': IS_VOTING_BOARD ? screenStore.isMobile : false,
        transparent: IS_IFRAME_TRANSPARENT,
        'App--frame': IS_IFRAME || IS_IFRAME_WIDGET,
    });

    return (
        <TooltipProvider>
            <div className={classes} {...props} data-offline={mainStore.disconnected} />
        </TooltipProvider>
    );
});

AppWrapperDiv.displayName = 'AppWrapperDiv';

function AppWrapper({ contentOnly, ...props }) {
    if (contentOnly) {
        return <AppWrapperDiv {...props} />;
    }

    if (utilsStore.showingTypeform) {
        return null;
    }

    return (
        <Router>
            <HotkeysDialogProvider>
                <AppWrapperDiv {...props} />
            </HotkeysDialogProvider>
        </Router>
    );
}

export default observer(AppWrapper);

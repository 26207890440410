import React from 'react';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { Button } from 'components/Button';
import PlatformIcon from 'components/PlatformIcon';
import Spinner from 'components/Spinner';

export function itemRenderer(item, { modifiers, handleClick }) {
    const text = item.key ? `${item.key}: ${item.name}` : item.name;

    return (
        <Button
            text={text}
            block
            minimal
            active={modifiers.active}
            key={item.id}
            onClick={handleClick}
            title={text}
            rightElm={
                modifiers.disabled ? (
                    <Spinner size={16} />
                ) : item.provider || item.isDucalis ? (
                    <PlatformIcon provider={item.isDucalis ? PROVIDER_DUCALIS : item.provider} />
                ) : undefined
            }
        />
    );
}

import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import { SERVICE_DOMAIN_NAME } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

async function shareIt(idea) {
    try {
        await navigator.share({ title: SERVICE_DOMAIN_NAME, text: idea.name, url: idea.href });
    } catch (err) {
        idea.copyPublicLink();
    }
}

function MobileShareButton() {
    return <Button size={28} border onClick={() => shareIt(issuesList.activeIssue)} icon={CustomIconName.SHARE} />;
}

export default observer(MobileShareButton);

import React, { useEffect, useMemo, useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import api from 'api';
import { logEvent } from 'utils';

import { Idea } from 'store/models/Idea';

import { defaultPopoverProps, POPOVER_PROPS_DIV } from 'utils/consts';
import debounce from 'utils/debounce';

import { Button } from 'components/Button';
import IssueName from 'components/IssueName';

import { IdeasPopoverContent } from './IdeasPopoverContent';

export const IdeaNameRecommended = ({ issue, placeholder }) => {
    const [open, setOpen] = useState(true);
    const [ideas, setIdeas] = useState([]);

    const changeHandler = async (name) => {
        if (!name) return setIdeas([]);

        try {
            const { data } = await api.get(`${issue.board.apiEndpoint}/ideas/similar`, { params: { name } });
            setIdeas(data.map((el) => new Idea({ ...el, boardId: el.board_public_id })));
        } catch (e) {
            console.error('Fail request Ideas Similar', e);
        }
    };

    const closeSuggestions = () => {
        setOpen(false);
        const el = document.querySelector('.bp5-portal .bp5-editable-text');
        el && el.focus();
    };

    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), []);

    const handleClickOpen = () => {
        setOpen(true);
        logEvent('Click - Show Suggested ideas');
    };

    return (
        <>
            <Popover
                {...defaultPopoverProps}
                {...POPOVER_PROPS_DIV}
                enforceFocus={false}
                autoFocus={false}
                shouldReturnFocusOnClose={false}
                openOnTargetFocus={false}
                isOpen={ideas.length > 0 && open}
                content={<IdeasPopoverContent ideas={ideas} onClose={closeSuggestions} />}
            >
                <IssueName
                    onChange={debouncedChangeHandler}
                    placeholder={placeholder}
                    issue={issue}
                    editable
                    autoFocus
                />
            </Popover>

            {!open && (
                <div style={{ padding: '4px 0' }}>
                    <Button minimal size={20} border onClick={handleClickOpen}>
                        <FormattedMessage
                            id="idea.show_suggested"
                            defaultMessage="Show similar ideas: {ideasLength}"
                            values={{
                                ideasLength: ideas.length,
                            }}
                        />
                    </Button>
                </div>
            )}
        </>
    );
};

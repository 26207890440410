import React, { useCallback, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { issuesList } from 'store/models/IssuesList';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DotDivider from 'components/DotDivider';
import { COMBOS } from 'components/Hotkeys/constants';
import Kbd, { KeyCombo } from 'components/Kbd';
import Tooltip from 'components/Tooltip';

function DesktopCopyLinkButton({ hotkey = 'mod+shift+,', className }) {
    const intl = useIntl();

    const tooltipIntl = intl.formatMessage({
        id: 'tooltip.copy-idea-link',
        defaultMessage: 'Copy Idea Link',
    });

    const idea = issuesList.activeIssue;

    const copyLink = useCallback(() => {
        const notificationIntl = intl.formatMessage({
            id: 'notification.copy',
            defaultMessage: 'Copied to clipboard',
        });

        idea.copyPublicLink(notificationIntl);
    }, [intl, idea]);

    const hotkeys = useMemo(() => {
        return [
            {
                ...COMBOS.copyIssueLink,
                combo: hotkey,
                global: true,
                disabled: idea?.id === -1,
                onKeyDown: copyLink,
            },
        ];
    }, [copyLink, idea, hotkey]);

    useHotkeys(hotkeys);

    if (!idea || idea.id === -1) {
        return null;
    }

    return (
        <Tooltip
            side="top"
            align="start"
            content={
                <>
                    {tooltipIntl}
                    <DotDivider />
                    <KeyCombo>
                        <Kbd>cmd</Kbd> <Kbd>shift</Kbd> <Kbd>,</Kbd>
                    </KeyCombo>
                </>
            }
        >
            <Button
                border
                className={className}
                tabIndex={-1}
                onClick={copyLink}
                size={28}
                icon={CustomIconName.LINK}
            />
        </Tooltip>
    );
}

export default observer(DesktopCopyLinkButton);

import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_LINEAR } from 'constants/Providers';

import { issuesList } from 'store/models/IssuesList';

import MetaItem from 'components/MetaItem';

const LinearProject = () => {
    const issue = issuesList.issue;

    if (!issue.customFields?.project || issue.provider !== PROVIDER_LINEAR) return null;

    return (
        <MetaItem title="Project">
            <div className="t-crop" title={issue.customFields.project}>
                {issue.customFields.project}
            </div>
        </MetaItem>
    );
};

export default observer(LinearProject);

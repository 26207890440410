import { makeAutoObservable } from 'mobx';

import { PROVIDER_DUCALIS } from 'constants/Providers';
import { logEvent } from 'utils';

import { mainStore } from 'store/models/MainStore';

import { FILTER_TYPES, SORT_DIRECTION, UNSAVED_MODEL_ID, VIEWS } from 'utils/consts';
import isEmptyValue from 'utils/isEmptyValue';
import myOrderBy from 'utils/orderBy';

export class View {
    sortField = null;
    sortDirection = SORT_DIRECTION.asc;
    sortFieldType = undefined;
    sortedIds = null;
    filter = null;
    title = null;
    id = null;
    icon = null;
    author = null;
    disabledAutoSort = false;

    criterion_id = null;
    fieldType = null;
    ducalis_field = null;
    votingField = null;

    constructor(data) {
        Object.assign(this, data);

        makeAutoObservable(this);
    }

    get isActiveColumn() {
        if (!this.sortField) return null;

        const activeModel = mainStore.report || mainStore.activeBoard;
        const viewId = this.id === VIEWS.SCORES ? VIEWS.EVALUATION : this.id;
        const isActiveColumns = activeModel.getColumnsByGrid(viewId) || [];
        return isActiveColumns.some((el) => el.id === this.sortField);
    }

    get isStringField() {
        return [
            FILTER_TYPES.USER,
            FILTER_TYPES.USER_LIST,
            FILTER_TYPES.STRING,
            FILTER_TYPES.DATETIME,
            FILTER_TYPES.DATE,
            FILTER_TYPES.LIST,
        ].includes(this.sortFieldType);
    }

    setSortIds(ids) {
        this.sortedIds = ids;
    }

    setSort(props) {
        const {
            criterion_id = null,
            ducalis_field = null,
            fieldType = null,
            id = null,
            type,
            votingField = null,
            direction = SORT_DIRECTION.asc,
            fixed = this.disabledAutoSort,
        } = props || {};

        this.sortField = id ? String(id) : null;
        this.sortDirection = direction;
        this.sortFieldType = type;
        this.disabledAutoSort = fixed;

        this.criterion_id = criterion_id;
        this.fieldType = fieldType;
        this.ducalis_field = ducalis_field;
        this.votingField = votingField;
    }

    sortIssuesByIds = (issue) => {
        if (issue.id === -1) return this.lastValue;

        const index = this.sortedIds.indexOf(issue.id);
        if (index === -1 && this.sortDirection === SORT_DIRECTION.asc) {
            return Infinity;
        }
        return index;
    };

    prepareUserValue(value) {
        if (FILTER_TYPES.USER === this.sortFieldType) {
            return value?.name ?? value;
        }
        if (FILTER_TYPES.USER_LIST === this.sortFieldType) {
            if (!Array.isArray(value)) {
                return value;
            }
            return value
                .map((el) => el?.name ?? el)
                .filter((el) => el)
                .join(', ');
        }
        return value;
    }

    get lastValue() {
        return this.sortDirection === SORT_DIRECTION.asc ? null : '';
    }

    get infinity() {
        return this.sortDirection === SORT_DIRECTION.asc ? Infinity : -Infinity;
    }

    sortIssues = (issue) => {
        if (issue.id === UNSAVED_MODEL_ID) return this.lastValue;

        // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
        // let val;
        // if (this.sortField === 'row_number' && mainStore.page === VIEWS.TOP && mainStore.activeModel.considerBlockers) {
        //     val = issue.getValueByField('blockRowNumber', this.sortFieldType);
        // } else {

        const field =
            this.votingField ||
            this.criterion_id ||
            (issue.provider === PROVIDER_DUCALIS && this.ducalis_field) ||
            this.sortField;

        const val = this.prepareUserValue(issue.getValueByField(field, this.sortFieldType, this.fieldType));
        // };

        if (Array.isArray(val)) {
            return val.length === 0 ? this.lastValue : val.join(', ');
        }

        if (this.isStringField) {
            return isEmptyValue(val) ? this.lastValue : val;
        }

        return isEmptyValue(val) ? this.infinity : val;
    };

    defaultIssuesIdSort = (issue) => {
        if (issue.id === -1) return this.lastValue;
        return issue.id;
    };

    getDisabledSortList(list) {
        const indexMap = {};
        list.forEach((item, index) => {
            indexMap[item.id] = index;
        });
        return this.sortedIds.reduce((acc, id) => {
            const index = indexMap[id];
            const issue = list[index];
            if (issue) acc.push(issue);
            return acc;
        }, []);
    }

    /**
     *
     * @param {[Issue]|[Idea]} list
     * @param {boolean=} defSort
     * @return {[Issue]|[Idea]}
     */
    useSort(list, defSort) {
        if (!this.sortField || (this.isActiveColumn === false && this.sortFieldType !== 'input')) {
            return defSort ? myOrderBy(list, this.defaultIssuesIdSort) : list;
        }

        const direction = this.sortDirection.toLowerCase();

        if (this.disabledAutoSort && this.sortedIds) {
            return this.getDisabledSortList(list);
        }
        const sortList = myOrderBy(list, this.sortIssues, direction);

        const ids = sortList.map((el) => el.id);
        this.setSortIds(this.sortDirection === SORT_DIRECTION.desc ? ids.reverse() : ids);

        return sortList;
    }

    getSortState() {
        return {
            id: this.sortField,
            direction: this.sortDirection,
            type: this.sortFieldType,
            fixed: this.disabledAutoSort,
            criterion_id: this.criterion_id,
            fieldType: this.fieldType,
            ducalis_field: this.ducalis_field,
            votingField: this.votingField,
        };
    }

    toggleAutoSort = () => {
        logEvent('Toggle AutoSort', { status: !this.disabledAutoSort });
        this.disabledAutoSort = !this.disabledAutoSort;

        mainStore.activeModel.saveSortSettings();
    };
}

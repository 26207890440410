import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { PROVIDER_DUCALIS } from 'constants/Providers';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS, POPOVER_PROPS_DIV, SERVICE_NAME } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import FakePushToTracker from 'components/FakePushToTracker';
import Flex from 'components/Flex';
import PushToTracker from 'components/PushToButton/PushToTracker';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

import MakeDucalisIssue from './MakeDucalisIssue';

const PushToButtonMenuContent = observer(({ setLoader }) => {
    const idea = issuesList.idea;
    const showAddTracker = mainStore.currentUser?.isAdmin && idea.board.provider === PROVIDER_DUCALIS;

    return (
        <Flex column align="none" css={{ width: 252, padding: 4 }} gap={4}>
            <PushToTracker />
            <MakeDucalisIssue setLoader={setLoader} />
            {showAddTracker && <FakePushToTracker boardId={idea.board_id} />}
        </Flex>
    );
});
function PushToButton({ disabled }) {
    const [loader, setLoader] = useState(false);

    if (!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG)) {
        return (
            <UpgradePlanBtn
                paywall={PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG}
                text="to unlock this feature."
                helpArticleText="Add to Backlog"
                helpArticle="voting-board/#creating-a-public-version-of-your-backlog"
            >
                <Button
                    color="primary"
                    size={24}
                    icon={CustomIconName.PLUS}
                    disabled
                    text="New"
                    rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
                />
            </UpgradePlanBtn>
        );
    }

    const button = (
        <DarkTooltip
            fast
            content={`Create new issue and add it to your task tracker backlog or to ${SERVICE_NAME} board`}
            position="top"
        >
            <Button
                border
                loading={loader}
                color="primary"
                size={24}
                icon={CustomIconName.PLUS}
                disabled={disabled}
                text="New"
                rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
            />
        </DarkTooltip>
    );

    if (disabled) return button;

    return (
        <div>
            <Popover
                {...POPOVER_PROPS_DIV}
                usePortal={false}
                minimal
                position="bottom-right"
                content={<PushToButtonMenuContent setLoader={setLoader} />}
            >
                {button}
            </Popover>
        </div>
    );
}

export default observer(PushToButton);

import { makeAutoObservable, observable, reaction, runInAction } from 'mobx';

import api from 'api';
import { EMPTY_ARRAY } from 'constants/atoms';
import { PROVIDER_DUCALIS } from 'constants/Providers';

import alertStore from 'store/models/Alert';
import * as IdeaApi from 'store/models/api/Idea';
import * as IssueApi from 'store/models/api/Issue';
import Comment from 'store/models/Comment';
import { Issue } from 'store/models/Issue';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import screenStore from 'store/models/ScreenStore';
import { clearDescription } from 'store/models/utils';
import { utilsStore } from 'store/models/UtilsStore';

import {
    CURRENT_USER_ID,
    CURRENT_USER_IS_ADMIN,
    DICTIONARY_LABELS_VOTING,
    DICTIONARY_STATUSES_VOTING,
    IS_IFRAME,
    IS_PUBLIC_BOARD,
    IS_VOTING_BOARD,
    UNSAVED_MODEL_ID,
} from 'utils/consts';
import copyToClipboard from 'utils/copyToClipboard';
import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import { sendToSentry } from 'utils/sentry';
import toUrl from 'utils/toUrl';

import { GhostNotification } from 'components/AppToaster';

import Announce from './Announce';
import { fillWithInterval } from './utils/fillWithInterval';

export class Idea {
    /** number|string **/
    boardId = null;
    board_id = 0;
    description = '';
    /** number **/
    id = null;
    name = '';
    row_number = null;
    tempId = null;
    provider = null;
    requestsIds = new Map();
    label_ids = [];
    customFields = {};
    status_id = null;
    is_demo = false;
    isRemoved = false;

    issue_id = null;
    allow_voting = false;
    announce_hidden = null;
    has_announce = false;
    votesCount = 0;
    votersCount = 0;
    author = null;
    created = null;
    issue = null;
    onEdit = false;
    last_status_update = '';
    last_update = '';
    announce_text_status = '';
    vote_stats = null;
    parent_id = null;
    unread = false;
    mentionsUsers = null;
    announce_id = null;
    source = null;
    text_status = 'ready'; // ready, ai_in_progress, ai_ready
    _links = null;

    backupData = null;
    scrollCommentId = null;

    votersData = [];
    users = [];

    aiText = '';
    aiName = '';

    votingAnnounce = null;
    initialOpenAnnounce = false;

    /**
     * Template vote for unsaved Idea
     */
    tmpVote;

    constructor(data, votingAnnounce) {
        makeAutoObservable(this, {
            votingAnnounce: false,

            requestsIds: observable.shallow,
            label_ids: observable.struct,
            mentionsUsers: observable.shallow,
            votersData: observable.shallow,
        });

        this.id = data.id || -1;
        this.board_id = data.board_id || 0;
        this.name = data.name;
        this.description = clearDescription(data.description, PROVIDER_DUCALIS);
        this.boardId = data.boardId;
        this.tempId = data.tempId || null;
        this.provider = data.provider || null;
        this.label_ids = data.label_ids || [];
        this.status_id = data.status_id || null;
        this.is_demo = data.is_demo || false;
        this.customFields = data.customFields || {};
        this.issue_id = data.issue_id;
        this.announce_text_status = data.announce_text_status;
        this.allow_voting = !!data.allow_voting || false;
        this.announce_hidden = data.announce_hidden;
        this.has_announce = data.has_announce || false;
        this.votesCount = data.votesCount || 0;
        this.votersCount = data.votersCount || 0;
        this.author = data.author || null;
        this.last_status_update = data.last_status_update || '';
        this.last_update = data.last_update || '';
        this.parent_id = data.parent_id || null;
        this.unread = data.unread || false;
        this.created = data.created || null;
        this.votersData = data.votersData || [];
        this.announce_id = data.announce_id || null;
        this.mentionsUsers = data.mentionsUsers || null;
        this.votingAnnounce = votingAnnounce || data.votingAnnounce || null;
        this.text_status = data.text_status || this.text_status;
        this.source = data.source;
        this._links = data._links;

        const requests = data.requests || data.comments || EMPTY_ARRAY;
        requests.forEach((comment) => this.requestsIds.set(comment.id, new Comment(comment, this)));

        reaction(
            () => this.initialOpenAnnounce,
            (initialOpenAnnounce) => {
                initialOpenAnnounce && delay(5000).then(() => this.set({ initialOpenAnnounce: false }));
            },
            {
                onError: (error) => {
                    console.error('Reaction error:', error);
                },
            },
        );
    }

    fillVotesStats(data = null) {
        this.vote_stats = data;
    }

    getKey() {
        return this.tempId || this.id;
    }

    get requests() {
        return Array.from(this.requestsIds.values());
    }

    get dateUpdate() {
        return this.isDone ? this.last_status_update || this.last_update : this.last_update;
    }

    get fullName() {
        return this.name;
    }

    /**
     * Idea's author email + name
     * Used for filter, don`t rename
     *
     * @returns {string|null}
     */
    get ideaAuthor() {
        return this.author?.id ?? null;
    }

    get mergedIssues() {
        if (!this?.board?.mergedIdeas) {
            return [];
        }
        return this.board.mergedIdeas.filter((idea) => this.id === idea.parent_id);
    }

    update(field) {
        this.set(field);

        const canAutoSave = this.id !== UNSAVED_MODEL_ID || (this.id === UNSAVED_MODEL_ID && !!this.issue_id);

        if (!IS_PUBLIC_BOARD && canAutoSave) {
            const { name, description, status, allow_voting } = this;
            this.save({
                name,
                description,
                status,
                allow_voting,
                ...field,
            });
        }
    }

    updateRequests(list) {
        const activeIds = new Set(Array.from(this.requestsIds.keys()));

        list.forEach((comment) => {
            if (activeIds.has(comment.id)) {
                this.requestsIds.get(comment.id).update(comment);
                activeIds.delete(comment.id);
            } else {
                this.requestsIds.set(comment.id, new Comment(comment, this));
            }
        });

        activeIds.forEach((id) => this.requestsIds.delete(id));
    }

    set({ comments, ...fieldObj }) {
        Object.assign(this, fieldObj);

        if (comments) {
            this.updateRequests(comments);
        }
    }

    /**
     * Array of  Labels names
     * Used for filter, don`t rename
     *
     * @returns {[string]}
     */
    get labelsNames() {
        return this.labelsList.map((el) => el.name);
    }

    get isDone() {
        if (!this.board) {
            console.error('FAIL GET DONE STATUS', this.board, this.board_id);
            return false;
        }
        return this.status_id === this.board.doneIdeaStatusId;
    }

    get statusName() {
        return this.innerStatus?.name ?? '';
    }

    get href() {
        if (!this.board) return '';

        return `${this.board.votingLink}/issue-${this.id}`;
    }

    get internalHref() {
        if (!this.board || this.id <= 0) {
            return '';
        }
        if (IS_VOTING_BOARD) {
            return `${this.board.basePath}/issue-${this.id}`;
        }
        return `${this.board.basePath}/public-ideas/idea-${this.id}`;
    }

    get isEditable() {
        return IS_VOTING_BOARD ? this.author?.id === mainStore.currentUser?.id : this.parentIssue?.isEditable;
    }

    /**
     * @return {Announce|null}
     */
    get announce() {
        if (this.votingAnnounce instanceof Announce) return this.votingAnnounce;
        if (!this.announce_id || !mainStore.announcesIds.has(this.announce_id)) return null;

        return mainStore.announcesIds.get(this.announce_id);
    }

    /**
     * @return {Issue|null}
     */
    get parentIssue() {
        if (!this.issue_id) {
            return null;
        }
        if (this.issue?.id === this.issue_id) {
            return this.issue;
        } else if (!this.board) {
            return null;
        }
        return issuesList.allIssues.find((issue) => issue.id === this.issue_id) || null;
    }

    /**
     * Find ideas with comment
     * Used for filter, don`t rename
     *
     * @returns {boolean}
     */
    get hasComments() {
        return this.requestsIds.size > 0;
    }

    /**
     * Find ideas with parent Issue
     * Used for filter, don`t rename
     *
     * @returns {boolean}
     */
    get hasIssue() {
        return !!this.issue_id;
    }

    get apiBaseEndpoint() {
        return `/boards/${this.board_id}/ideas`;
    }

    get apiCurrentEndpoint() {
        return `${this.apiBaseEndpoint}/${this.id}`;
    }

    get board() {
        return mainStore.boardsList.boards.find((board) => [this.boardId, this.board_id].includes(board.id));
    }

    get comments() {
        if (!this.requestsIds.size) {
            return EMPTY_ARRAY;
        }
        return this.requests.filter((comment) => comment.parent_id === null);
    }

    get labelsList() {
        const list = this.board?.ideaLabels ?? [];
        return this.label_ids.map((id) => list.find((el) => el.id === id)).filter((el) => el);
    }

    get statusObj() {
        return this.innerStatus;
    }

    get innerStatus() {
        if (!this.status_id || !this.board) {
            return null;
        }
        return this.board.ideaStatuses.find((el) => el.id === this.status_id);
    }

    get isEmpty() {
        return !this.name || !this.description;
    }

    getDictByName(dictionary) {
        return this.board?.getDictByName(dictionary) ?? [];
    }

    getFieldValueByDictionary(dictionary) {
        switch (dictionary) {
            case DICTIONARY_LABELS_VOTING:
                return this.labelsList;
            case DICTIONARY_STATUSES_VOTING:
                return this.innerStatus;
            default:
                return null;
        }
    }

    copyPublicLink = (notification = 'Copied to clipboard') => {
        if (this.id === UNSAVED_MODEL_ID) return;

        logEvent('Copy Public Idea link');
        copyToClipboard(this._links.votingUrl);
        GhostNotification.show({ message: notification, timeout: 1000 });
    };

    hasText(query) {
        if (!this.name) {
            return false;
        }
        const name = this.name.toString();
        const normalizedName = name.toLowerCase();
        const normalizedQuery = query.toString().toLowerCase();
        return normalizedName.indexOf(normalizedQuery) >= 0;
    }

    copyInternalLink = (notify = true) => {
        if (this.id === UNSAVED_MODEL_ID || screenStore.isMobile) {
            return;
        }
        logEvent('Copy issue link');
        copyToClipboard(this._links.internalUrl);
        notify && GhostNotification.show({ message: 'Copied to clipboard', timeout: 1000 });
    };

    async moveToBacklog() {
        try {
            await api.post(`${this.apiCurrentEndpoint}/backlog`);
        } catch (e) {
            failRequest(e);
        }
    }

    async mergeWithIssue(issue_id) {
        try {
            const { data } = await api.post(`${this.apiCurrentEndpoint}/issue-merge`, toUrl({ issue_id }));
            return data;
        } catch (e) {
            failRequest(e);
            return await Promise.reject();
        }
    }

    async unMergeWithIssue() {
        try {
            await api.delete(`${this.apiCurrentEndpoint}/issue-merge`);
        } catch (e) {
            failRequest(e);
            return await Promise.reject();
        }
    }

    async unMergeWithIdea() {
        try {
            await api.delete(`${this.apiCurrentEndpoint}/idea-merge`);
        } catch (e) {
            failRequest(e);
            return await Promise.reject();
        }
    }

    async changeAuthor(email) {
        try {
            await api.post(`${this.apiCurrentEndpoint}/change-author`, toUrl({ email }));
        } catch (e) {
            failRequest(e);
            return await Promise.reject();
        }
    }

    async save({ name, description, allow_voting, status_id }) {
        const dataRequest = { name, description, status_id, allow_voting };

        if (this.id === UNSAVED_MODEL_ID) {
            try {
                dataRequest.issue_id = this.issue_id;
                dataRequest.vote = this.tmpVote;
                const { data } = await api.post(this.apiBaseEndpoint, toUrl(dataRequest));

                const idea = issuesList.ideasIds.get(data.id);
                this.set(data);

                if (idea) {
                    idea.set({ tempId: this.tempId });
                } else {
                    runInAction(() => {
                        issuesList.ideasIds.set(data.id, this);
                    });
                }

                if (IS_VOTING_BOARD) {
                    runInAction(() => {
                        mainStore.votingVotes.set(data.id, dataRequest.vote || 1);
                    });

                    mainStore.currentUser.checkOptIn();
                }

                if (!IS_PUBLIC_BOARD && !this.issue_id) {
                    document.querySelector(`#issue-${this.id}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                issuesList.activeIssue?.id === UNSAVED_MODEL_ID && issuesList.setActiveIssue(idea || this);
            } catch (e) {
                failRequest(e);
            }
        } else {
            IS_VOTING_BOARD && this.toggleEditMode();
            try {
                await api.put(this.apiCurrentEndpoint, toUrl(dataRequest));
            } catch (e) {
                failRequest(e);
            }
        }
    }

    get userMaxCountVotes() {
        if (!this.board.voting_settings?.multiple_upvotes) {
            return 1;
        }
        return this.board?.voting_settings?.votesLabels?.length || 1;
    }

    get userLimit() {
        return mainStore.votingVotes.get(this.id) || 0;
    }

    get canVote() {
        return this.userLimit < this.userMaxCountVotes;
    }

    get mobileDesc() {
        if (!this.description) return '';
        const res = this.description
            .replace(/<img .+?>/g, '')
            .replace('<p></p>', '')
            .match(/<p>(.*?)<\/p>/gi);
        return (res && res?.join(' ')?.replace(/(<p>|<\/p>)/gi, '')) || this.description;
    }

    get firstImage() {
        if (!this.description) return null;

        const res = this.description.match(/<img .+?>/g, '');
        return res?.[0] || null;
    }

    get firstImagePath() {
        if (!this.firstImage) return null;
        const res = this.firstImage.match(/src\s*=\s*"(.+?)"/, '');
        const src = res?.[1] || null;
        if (src) return `${window.location.origin}${src}`;
        return null;
    }

    /**
     * For filter
     */
    get companyNames() {
        return this.users
            .filter((user) => user.company?.name)
            .map((user) => user.company.name)
            .join(', ');
    }

    clearScrollId() {
        this.scrollCommentId = null;
    }

    restoreFromBackup() {
        if (this.backupData) {
            Object.entries(this.backupData).forEach(([key, value]) => (this[key] = value));
            this.backupData = null;
        }
    }

    toggleEditMode = () => {
        if (!this.onEdit) {
            this.backupData = {
                name: this.name,
                description: this.description,
            };
        }
        this.onEdit = !this.onEdit;
    };

    get isEditMode() {
        return this.onEdit || this.id === UNSAVED_MODEL_ID;
    }

    async voting(vote = true, value) {
        if (!CURRENT_USER_ID) {
            utilsStore.setHistoryAction({ voting: { vote, id: this.id, value } });
            return;
        }
        const canDownVote = this.userLimit > 0;

        if (value === undefined && ((vote && !this.canVote) || (!vote && !canDownVote))) {
            return;
        }

        runInAction(() => {
            const count = mainStore.votingVotes.get(this.id);
            let votesCount;

            if (!this.board.voting_settings.card_display_upvotes) {
                // Display only voters count
                if (!count) {
                    // first iteration - add vote
                    votesCount = this.votesCount + 1;
                } else if ((count === 1 && !vote) || (count && value === 0)) {
                    // remove all votes
                    votesCount = this.votesCount - 1;
                } else {
                    // another iteration - don`t change the counter
                    votesCount = this.votesCount;
                }
            } else if (value >= 0 && this.board.voting_settings.card_display_upvotes) {
                // Display count votes - change via selector
                votesCount = this.votesCount - (count || 0) + value;
            } else {
                // Display count votes - change via button
                votesCount = this.votesCount + (vote ? 1 : -1);
            }
            this.votesCount = votesCount < 0 ? 0 : votesCount;
        });

        this.board.setUserVote(vote, this.id, value);

        if (this.id === UNSAVED_MODEL_ID) {
            this.tmpVote = value;
            return;
        }

        try {
            const url = `${this.apiCurrentEndpoint}/vote`;
            const dataRequest = toUrl({ value });
            if (vote) {
                await api.post(url, dataRequest);
            } else {
                await api.delete(url, { data: dataRequest });
            }

            mainStore.currentUser.checkOptIn();
        } catch (e) {
            failRequest(e);
        }
    }

    async getIssue() {
        if (IS_PUBLIC_BOARD || this.issue === undefined) {
            return;
        }

        const anotherBoardIssue = issuesList.allIssues.find((issue) => issue.id === this.issue_id);
        if (anotherBoardIssue) {
            this.set({ issue: anotherBoardIssue });
            return;
        }

        try {
            const { data } = await api.get(`/boards/${this.board_id}/issues/${this.issue_id}`, {
                params: { voting: 1 },
            });
            this.set({ issue: new Issue({ ...data, boardId: data.board_id || this.board_id, isExternal: true }) });
        } catch (e) {
            sendToSentry('Fail get issue', {
                path: `/boards/${this.board_id}/issues/${this.issue_id}`,
            });
            this.set({ issue: undefined });
        }
    }

    remove = () => {
        alertStore.confirm({
            message:
                'Do you really want to permanently remove the public description of this issue from the board? The issue will no longer be seen by external users on the voting board.',
            onConfirm: () => this.removeIssueRequest(),
            confirmButtonText: 'Remove',
        });
    };

    async removeIssueRequest() {
        logEvent('Remove voting issue');
        if (!IS_VOTING_BOARD && this.is_demo) {
            this.board.toggleIdeaDemoRemover(true);
        }
        try {
            await api.delete(this.apiCurrentEndpoint);
        } catch (e) {
            sendToSentry('Fail remove voting issue', {
                path: this.apiCurrentEndpoint,
            });
        }
    }

    async customVote({ email }) {
        logEvent('Vote on Behalf of a User');
        return await api.post(`${this.apiCurrentEndpoint}/custom-vote`, toUrl({ email }));
    }

    addHandleComment(comment) {
        if (this.requestsIds.has(comment)) {
            this.requestsIds.get(comment.id).update(comment);
        } else {
            this.requestsIds.set(comment.id, new Comment(comment, this));
        }
    }

    async sendComment(message, parent_id = null, assignee_id = null) {
        try {
            const { data } = await api.post(
                `${this.apiCurrentEndpoint}/comments`,
                toUrl({ message, parent_id, assignee_id }),
            );
            data.user.id !== mainStore.currentUser?.id && mainStore.currentUser?.setId(data.user.id);
            runInAction(() => {
                this.scrollCommentId = data.parent_id || data.id;
                if (IS_VOTING_BOARD) {
                    this.addHandleComment(data);
                }
            });
        } catch (e) {
            failRequest(e);
        }
    }

    async commentQuestion({ message, question }) {
        logEvent('Add comment');
        await this.sendComment(message, question?.id);
    }

    async updateRequest({ request, message = null, assignee_id = null }) {
        logEvent('Update comment');
        try {
            await api.put(`${this.apiCurrentEndpoint}/comments/${request.id}`, toUrl({ message, assignee_id }));
        } catch (error) {
            failRequest(error);
        }
    }

    async removeRequest({ id }) {
        this.requestsIds.delete(id);
        try {
            await api.delete(`${this.apiCurrentEndpoint}/comments/${id}`);
        } catch (e) {
            failRequest(e);
        }
    }

    async resolveRequest() {}

    async setProps({ dictionary, value }) {
        if (dictionary === DICTIONARY_LABELS_VOTING) {
            return this.setLabel(value);
        } else if (dictionary === DICTIONARY_STATUSES_VOTING) {
            return this.update({ status_id: value.id });
        }
    }

    async setLabel(label) {
        if (this.label_ids.includes(label.id)) return;

        runInAction(() => {
            if (this.label_ids) {
                this.label_ids = [...this.label_ids, label.id];
            } else {
                this.label_ids = [label.id];
            }
        });
        try {
            await api.post(`${this.apiCurrentEndpoint}/labels/${label.id}`);
        } catch (error) {
            failRequest(error);
        }
    }

    async removeLabel(label) {
        runInAction(() => {
            this.label_ids = this.label_ids.filter((id) => id !== label.id);
        });
        try {
            await api.delete(`${this.apiCurrentEndpoint}/labels/${label.id}`);
        } catch (error) {
            failRequest(error);
        }
    }

    downCounter() {
        if (!IS_IFRAME || !this.unread) return;
        try {
            window.parent.postMessage('minus1', '*');
        } catch (error) {}
    }

    async readIdea() {
        if (CURRENT_USER_IS_ADMIN) return;

        try {
            this.downCounter();
            await api.post(`${this.apiCurrentEndpoint}/mark-read?source=board`);

            runInAction(() => {
                this.unread = false;
                this.votingAnnounce && (this.votingAnnounce.unread = false);
            });

            await this.board.getUnreadCount();
        } catch (e) {
            sendToSentry('Fail read Idea', { error: e });
        }
    }

    setAIText = (text) => {
        if (this.aiText === '') {
            this.aiText = text.replace(/^>/, '');
        } else {
            this.aiText = this.aiText + text;
        }
    };

    clearAIText = () => {
        this.aiText = '';
        this.aiName = '';
    };

    get hasUnsavedAI() {
        if (!!this.aiText || !!this.aiName) return true;
        if (this.announce) {
            return !!this.announce.aiDescription || !!this.announce.aiName;
        }
        return false;
    }

    async getAnnounce() {
        if (!this.isDone || mainStore.announcesIds.has(this.announce_id)) return;

        try {
            const { data } = await api.get(`${this.board.apiEndpoint}/announces/${this.announce_id}`);

            runInAction(() => {
                mainStore.announcesIds.set(data.id, new Announce(data));
            });
        } catch (e) {
            failRequest(e);
        }
    }

    fillWithInterval = fillWithInterval;

    move = IssueApi.move;

    analyticsRead = IssueApi.analyticsRead;

    merge = IdeaApi.merge;

    fetchVoters = IdeaApi.fetchVoters;

    generateAIAnnounceName = IdeaApi.generateAIAnnounceName;

    generateAIAnnounceDesc = IdeaApi.generateAIAnnounceDesc;

    generateAI = IdeaApi.generateAI;

    getTitleByAI = IdeaApi.getTitleByAI;

    createIdeaAnnounce = IdeaApi.createIdeaAnnounce;

    getVoteStats = IdeaApi.getVoteStats;

    copyMoveToBoard = IdeaApi.copyMoveToBoard;
}

import { action, makeAutoObservable } from 'mobx';

import api from 'api';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { mainStore } from 'store/models/MainStore';
import { sortBoards } from 'store/models/utils/sortBoards';

import { IS_IFRAME, IS_PUBLIC_BOARD, IS_VOTING_BOARD } from 'utils/consts';
import failRequest from 'utils/failRequest';
import { groupBy } from 'utils/groupBy';
import logEvent from 'utils/logEvent';
import { sendToSentry } from 'utils/sentry';

import { Board } from './Board';

export default class Boards {
    /** @type {Map<any,Board>} */
    boardsIds = new Map();

    /** @type {string|number|null} */
    boardId = null;

    /** @type {Set<number>} */
    tasksBoards = new Set();

    /** @type {Map<string, string>} */
    aliasMaps = new Map();

    constructor() {
        makeAutoObservable(this, {
            setActiveBoardId: action,
        });
    }

    /**
     * @return {Board[]}
     */
    get boards() {
        return Array.from(this.boardsIds.values());
    }

    setActiveBoardId(id) {
        if (this.boardsId && id !== this.boardsId) {
            settingsStore.onClose();
        }

        if (!id) {
            this.boardId = null;
        } else {
            const boardId = isNaN(+id) ? id : +id;
            this.boardId = boardId;
            if (!IS_PUBLIC_BOARD) {
                const board = this.activeBoards.find((el) => el.id === boardId);
                board && board.getInviteSuggestions();
                return board;
            }
        }
    }

    set = (boards) => {
        if (IS_VOTING_BOARD) {
            boards.forEach((board) => this.aliasMaps.set(board.public_alias, board.public_id));
        }

        const activeIds = new Set(Array.from(this.boardsIds.keys()));

        boards.forEach((data) => {
            const boardId = data.id || data.public_id;
            if (this.boardsIds.has(boardId)) {
                this.boardsIds.get(boardId).fillModel(data);
                activeIds.delete(boardId);
            } else {
                this.boardsIds.set(boardId, new Board(data));
            }
        });

        activeIds.forEach((boardId) => this.boardsIds.delete(boardId));
    };

    /**
     * @return {Board[]}
     */
    get activeBoards() {
        if (IS_VOTING_BOARD) {
            return mainStore.currentUser?.isAdmin
                ? this.boards
                : this.boards.filter((board) => board.voting_settings.dashboard_visible);
        }

        const boards = this.boards.filter((board) => board.isVisible);
        return sortBoards(boards);
    }

    /**
     * @return {Board[]}
     */
    get activeVotingBoardsWithAccess() {
        return this.activeBoardsWithAccess.filter((board) => board.isVoting);
    }

    /**
     * @return {Board[]}
     */
    get activeBoardsWithAccess() {
        if (IS_VOTING_BOARD) {
            return this.boards;
        }
        return this.activeBoards.filter((board) => board.hasAccess);
    }

    /**
     * Get current board
     * @returns {Board|undefined|null}
     */
    get activeBoard() {
        if (this.boardId === null) return undefined;

        if (!this.boardsIds.size) return null;

        const boardId = IS_VOTING_BOARD && !IS_IFRAME ? this.aliasMaps.get(this.boardId) : this.boardId;
        const board = this.boardsIds.get(boardId);

        if (!board) {
            sendToSentry('404 - get activeBoard', {
                activeBoardId: this.boardId,
                isPublic: IS_PUBLIC_BOARD,
                boardsCount: this.boardsIds.size,
                boards: Array.from(this.boardsIds.keys()),
            });
            return null;
        }
        return board;
    }

    get boardsGroups() {
        const evaluationBoards = [];
        const votingBoards = [];
        const groups = groupBy(this.activeBoards, (board) => {
            board.isVoting && votingBoards.push(board);
            if (board.allCount > 0) {
                evaluationBoards.push(board);
            }

            switch (true) {
                case board.currentUserInBoard:
                    return 'My estimations';
                case board.hasAccess && !board.currentUserInBoard:
                    return 'View Only';
                case !board.hasAccess:
                    return 'Private boards';
                default:
                    return 'Another boards';
            }
        });

        if (votingBoards.length > 0) {
            groups['Voting'] = votingBoards;
        }
        if (evaluationBoards.length > 0) {
            groups['Evaluation'] = evaluationBoards;
        }

        return groups;
    }

    get boardsGroupKeys() {
        return Object.keys(this.boardsGroups);
    }

    getBoardById(boardId) {
        return this.activeBoards.find((el) => el.id === boardId);
    }

    updateBoard(data) {
        const boardId = data.id || data.public_id;
        const board = this.boardsIds.get(boardId);
        if (board) {
            board.fillModel(data);
        } else {
            this.boardsIds.set(boardId, new Board(data));
        }
    }

    removeSingle(boardId) {
        logEvent('leave board > removeSingle', { boardId });
        if (this.activeBoard?.id === boardId) {
            window.location.replace('/');
        }
        this.boardsIds.get(boardId)?.socket?.disconnect();
        this.boardsIds.delete(boardId);
    }

    setBoardTask(boardId) {
        this.tasksBoards.add(boardId);
    }

    removeBoardTask(boardId) {
        this.tasksBoards.delete(boardId);
        const board = this.boardsIds.get(boardId);
        board?.setWaiting(false);
    }

    get analyticsIssuesCount() {
        return this.activeBoards.reduce((res, board) => res + board.analyticsIssuesCount, 0);
    }

    get publicIssuesCount() {
        return this.activeBoards.reduce((res, board) => res + board.publicIssuesCount, 0);
    }

    async fetchTrash() {
        try {
            const { data } = await api.get(`/boards/bin`);
            return data
                .map((item) => ({
                    id: item.id,
                    emoji: item.emoji,
                    name: item.name,
                    total_remove_date: item.remove_date.total_remove_date,
                }))
                .sort((a, b) => {
                    if (a.remove_date === b.remove_date) return 0;
                    return a.remove_date > b.remove_date ? 1 : -1;
                });
        } catch (e) {
            failRequest(e);
        }
    }

    async restoreBoard(id) {
        try {
            await api.put(`/boards/${id}/restore`);
            return await this.fetchTrash();
        } catch (e) {
            failRequest(e);
            return await Promise.reject(e);
        }
    }
}

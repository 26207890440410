import React, { useEffect, useState } from 'react';

import { EditableText } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import { StCellWrapperBtnDropdown } from 'atoms/StCellWrapperBtnDropdown';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import PriorityIcon from 'components/MasterTable/components/PriorityIcon';
import Tooltip from 'components/Tooltip';

function IssueName({ issue, hideTop, index, disabled }) {
    const issueName = issue.name !== null ? (issue.name ?? '') : '';

    const [onEdit, setOnEdit] = useState(false);
    const [value, setValue] = useState(issueName);

    useEffect(() => {
        issueName !== value && setValue(issueName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueName]);

    function showDetails() {
        utilsStore.toggleCardHidden(false);
        issuesList.changeFocus({ row: index, col: issuesList.col, activeIssue: issue });
        logEvent('Show Issue Card From Cell Name');
    }

    const classes = 'flex flex-a-center gap-4';

    const priorityIcon = !issue.board?.hideScores && !hideTop && <PriorityIcon is_top={issue.isTop} />;
    const subtaskIcon = !disabled && issue.subtask && (
        <Tooltip fast content="Subtask" position="top" className="flex-shrink">
            <CustomIcon className="o-6" icon={CustomIconName.SUBTASK} />
        </Tooltip>
    );

    const buttonDetails =
        utilsStore.cardIsHidden && !onEdit ? (
            <StCellWrapperBtnDropdown>
                <Button
                    tabIndex="-1"
                    onClick={showDetails}
                    text="Details"
                    rightIcon={CustomIconName.CHEVRON_RIGHT}
                    size={24}
                    border
                    minimal
                />
            </StCellWrapperBtnDropdown>
        ) : undefined;

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // const model = mainStore.activeBoard || mainStore.report;
    // const blockedIcon = model.considerBlockers
    //     ? (issue.blockedByIssue && CustomIconName.BLOCKED_BY) || (issue.blocksIssues && CustomIconName.BLOCKS) || null
    //     : null;

    const icons = (
        <span className="flex flex-a-center flex-shrink">
            {/* TODO: https://concertwithme.atlassian.net/browse/DCLS-6798 */}
            {/*{blockedIcon && <CustomIcon className="t-blocked" icon={blockedIcon} />}*/}
            {priorityIcon}
            {subtaskIcon}
        </span>
    );

    if (issue.isEditable) {
        function updateName(name) {
            setOnEdit(false);
            if (name !== issue.name) {
                issue.update({ name, needFocus: undefined });
            }
        }

        const isEditing = issue.needFocus && utilsStore.cardIsHidden && issue.id === -1;

        return (
            <CellWrapper
                htmlProps={{ 'data-place': 'name' }}
                editable={issue.isEditable}
                className={classes}
                showBtn
                button={(issue?.id !== -1 && buttonDetails) || undefined}
            >
                {icons}
                <EditableText
                    isEditing={isEditing}
                    minWidth={20}
                    placeholder="Add Issue Name"
                    onEdit={() => setOnEdit(true)}
                    style={{ width: `100%` }}
                    tabIndex={0}
                    multiline={false}
                    confirmOnEnterKey={true}
                    value={value}
                    onConfirm={updateName}
                    onChange={setValue}
                />
            </CellWrapper>
        );
    }

    return (
        <CellWrapper htmlProps={{ 'data-place': 'name' }} className={classes} showBtn button={buttonDetails}>
            {icons}
            <span className="bp5-text-overflow-ellipsis">{issue.name}</span>
        </CellWrapper>
    );
}

export default observer(IssueName);
